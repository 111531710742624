import { default as error9jA5pByeDiMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/error.vue?macro=true";
import { default as indexYNa31WL3WOMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/index.vue?macro=true";
import { default as error_45pagev8Ult4y7PqMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/error-page.vue?macro=true";
import { default as none_45contents_45pageg1JRgBnqxWMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/none-contents-page.vue?macro=true";
import { default as privacy_45policyxObHJR1LteMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/privacy-policy.vue?macro=true";
import { default as usage_45policyH4q3GWWPx3Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/usage-policy.vue?macro=true";
import { default as changePass8Kx2HN30J5Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/changePass.vue?macro=true";
import { default as _10_questionwTWgClE9JZMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/10_question.vue?macro=true";
import { default as _1_serviceOverviewgVxMgyDgzjMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/1_serviceOverview.vue?macro=true";
import { default as _2_statusFEqkIXKA0uMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/2_status.vue?macro=true";
import { default as _3_equipSelectrWD0RFCFgtMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/3_equipSelect.vue?macro=true";
import { default as _4_instruIfrLzVciqUMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/4_instru.vue?macro=true";
import { default as _5_equipInfoVtzXb3MK2BMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/5_equipInfo.vue?macro=true";
import { default as _6_eventmydCxbVGwgMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/6_event.vue?macro=true";
import { default as _7_powerGenerationISLKZjMLjKMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/7_powerGeneration.vue?macro=true";
import { default as _8_installInfo2yW9U5pnDIMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/8_installInfo.vue?macro=true";
import { default as _9_ownerInfopNgxYBFxxsMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/9_ownerInfo.vue?macro=true";
import { default as indexxbPDYlXL9wMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/index.vue?macro=true";
import { default as _1_serviceOverviewD1ZPgKssYeMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/1_serviceOverview.vue?macro=true";
import { default as _2_statusZG1c7dvUNGMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/2_status.vue?macro=true";
import { default as _3_monitoring6yN5ch4oU3Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/3_monitoring.vue?macro=true";
import { default as _4_equipInfo11pMwTWufhMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/4_equipInfo.vue?macro=true";
import { default as _5_powerGenerationRmLmzEolZFMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/5_powerGeneration.vue?macro=true";
import { default as _6_reportNAdVOG5VRVMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/6_report.vue?macro=true";
import { default as _7_eventWfYn573MrUMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/7_event.vue?macro=true";
import { default as _8_ownerInfos09VqtdsHBMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/8_ownerInfo.vue?macro=true";
import { default as _9_questionfthUr3kdXeMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/9_question.vue?macro=true";
import { default as mainAEoYuDlL5uMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/main.vue?macro=true";
import { default as openSourceLnLSlcsdrgMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/openSource.vue?macro=true";
import { default as personTermsJEiiistaadMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/personTerms.vue?macro=true";
import { default as questiondAdAgTiTZ1Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/question.vue?macro=true";
import { default as useTermsDuGXynngdGMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/useTerms.vue?macro=true";
import { default as csG9fBVJ8lEhMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs.vue?macro=true";
import { default as equipListxAzaucVPzPMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/equipList.vue?macro=true";
import { default as equipMapIM4Khjs5gdMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/equipMap.vue?macro=true";
import { default as alarmETCWJzdjZtMdRMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm/alarmETC.vue?macro=true";
import { default as alarmOBSVzz8iDl8GIMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm/alarmOBS.vue?macro=true";
import { default as alarmRTU9BRiivohnRMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm/alarmRTU.vue?macro=true";
import { default as all9IPNBzpDjFMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm/all.vue?macro=true";
import { default as alarmZIujfY5imDMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm.vue?macro=true";
import { default as _91noticeid_93cYiHcz8KafMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/notice/[noticeid].vue?macro=true";
import { default as indexgAuBlw28BWMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/notice/index.vue?macro=true";
import { default as noticewjkYbSxEWTMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/notice.vue?macro=true";
import { default as etcUFhSHPh4U9Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc.vue?macro=true";
import { default as indexSSdr4XLR3kMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/index.vue?macro=true";
import { default as contact_45changeYrmtj18n2AMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/info/contact-change.vue?macro=true";
import { default as mainMDIRkpVqwjMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/info/main.vue?macro=true";
import { default as pw_45change47OCzxeKxPMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/info/pw-change.vue?macro=true";
import { default as user_45delete1qpQvFuIZvMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/info/user-delete.vue?macro=true";
import { default as infot2y8IN4z4nMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/info.vue?macro=true";
import { default as settingIWRx8jNp0SMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/setting.vue?macro=true";
import { default as siteListErxvUNtd33Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/siteList.vue?macro=true";
import { default as FailoverEditywvWSbyyjTMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/FailoverEdit.vue?macro=true";
import { default as indexgkjP3sEZOrMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstallInfo/index.vue?macro=true";
import { default as InstallInfoSwhfJ4t0wWMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstallInfo.vue?macro=true";
import { default as chartFTGbIZmMM3Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstruInfo/chart.vue?macro=true";
import { default as nowaJi6loUeHuMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstruInfo/now.vue?macro=true";
import { default as InstruInfohfGQzBiCr6Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstruInfo.vue?macro=true";
import { default as indexCihVb1PGAeMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/index.vue?macro=true";
import { default as esscvYXgtP2JCMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess.vue?macro=true";
import { default as index0IgzdQPccoMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstallInfo/index.vue?macro=true";
import { default as InstallInforg72yAKC9HMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstallInfo.vue?macro=true";
import { default as chartiUYrsVgP94Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstruInfo/chart.vue?macro=true";
import { default as nowsd3vvhrzpVMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstruInfo/now.vue?macro=true";
import { default as InstruInfoGrp0P9BPjUMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstruInfo.vue?macro=true";
import { default as indexwZymiGqByaMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/index.vue?macro=true";
import { default as fuelcelltHCJR5hHyfMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell.vue?macro=true";
import { default as installpcVtWr3LZFMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstallInfo/install.vue?macro=true";
import { default as managerbKmvmmftNXMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstallInfo/manager.vue?macro=true";
import { default as InstallInfobLmovg4SLyMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstallInfo.vue?macro=true";
import { default as chartv0vP2TV0bBMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstruInfo/chart.vue?macro=true";
import { default as nowbUa0gspGlYMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstruInfo/now.vue?macro=true";
import { default as InstruInfoXVBqd7kiJ0Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstruInfo.vue?macro=true";
import { default as rtuJz0xdvEaNRMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/SpecInfo/rtu.vue?macro=true";
import { default as specIKhLrlJ48VMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/SpecInfo/spec.vue?macro=true";
import { default as SpecInfor5CujBj14qMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/SpecInfo.vue?macro=true";
import { default as geoay8ugnngrAMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo.vue?macro=true";
import { default as installcQ6lRWaLkjMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstallInfo/install.vue?macro=true";
import { default as manager9cbTTfBcneMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstallInfo/manager.vue?macro=true";
import { default as InstallInfoNiUis5IdgvMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstallInfo.vue?macro=true";
import { default as chartwxqS5mFoz2Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstruInfo/chart.vue?macro=true";
import { default as nowe82nssE6XJMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstruInfo/now.vue?macro=true";
import { default as InstruInfoRhZ16bRPYpMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstruInfo.vue?macro=true";
import { default as rtuqE8BypqUbHMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/SpecInfo/rtu.vue?macro=true";
import { default as speci2Z6ZMqnrSMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/SpecInfo/spec.vue?macro=true";
import { default as SpecInfonVqm8edHVfMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/SpecInfo.vue?macro=true";
import { default as pvmHSlG35iW6Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv.vue?macro=true";
import { default as installznAQIpGrO0Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstallInfo/install.vue?macro=true";
import { default as managerxTB7esKWDKMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstallInfo/manager.vue?macro=true";
import { default as InstallInfoTIKk7TkFNWMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstallInfo.vue?macro=true";
import { default as chartrQT8OSn5U0Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstruInfo/chart.vue?macro=true";
import { default as now6ovcAH9yyhMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstruInfo/now.vue?macro=true";
import { default as InstruInfoaTpg21izIZMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstruInfo.vue?macro=true";
import { default as rtuRfuTSOLxJdMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/SpecInfo/rtu.vue?macro=true";
import { default as spec1NAv2DyAyhMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/SpecInfo/spec.vue?macro=true";
import { default as SpecInfo1nC2RBtRxyMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/SpecInfo.vue?macro=true";
import { default as solarheatTZhrMPkVXkMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat.vue?macro=true";
import { default as index3I7ymShIEcMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstallInfo/index.vue?macro=true";
import { default as InstallInfoX3d0eF4EfSMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstallInfo.vue?macro=true";
import { default as chartJzFC9q90ATMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstruInfo/chart.vue?macro=true";
import { default as nowxfzNcTr3qaMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstruInfo/now.vue?macro=true";
import { default as InstruInfoi3nLqP1NJxMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstruInfo.vue?macro=true";
import { default as indexGP4Fo4PFHyMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/index.vue?macro=true";
import { default as wfpCk5kbLvn8Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf.vue?macro=true";
import { default as indexFJqiKG2vP1Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstallInfo/index.vue?macro=true";
import { default as InstallInfoN6KFtmzJAJMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstallInfo.vue?macro=true";
import { default as chartOwoXBYgpvMMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstruInfo/chart.vue?macro=true";
import { default as nowqdWSTMUBAQMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstruInfo/now.vue?macro=true";
import { default as InstruInfouk9ylZF9tZMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstruInfo.vue?macro=true";
import { default as indexWATFweR744Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/index.vue?macro=true";
import { default as essEGKvXdJbF3Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess.vue?macro=true";
import { default as indexxiPIjWSInVMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstallInfo/index.vue?macro=true";
import { default as InstallInfoRRGdWEXvw2Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstallInfo.vue?macro=true";
import { default as chartvdX5eGanoCMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstruInfo/chart.vue?macro=true";
import { default as now1ONIcdfiOfMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstruInfo/now.vue?macro=true";
import { default as InstruInfojSDS3ucqO6Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstruInfo.vue?macro=true";
import { default as indexAW6D8zfI26Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/index.vue?macro=true";
import { default as fuelcelloVE8gBaMRjMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell.vue?macro=true";
import { default as installkZJRKwVRhvMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstallInfo/install.vue?macro=true";
import { default as manager9pgcVDxNPhMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstallInfo/manager.vue?macro=true";
import { default as InstallInfo1D9BRzGyXlMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstallInfo.vue?macro=true";
import { default as chartA32HGfXD06Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstruInfo/chart.vue?macro=true";
import { default as nowpUZ2fYhboPMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstruInfo/now.vue?macro=true";
import { default as InstruInfoplUk5vZJ6OMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstruInfo.vue?macro=true";
import { default as indexBWibM8nEBwMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/SpecInfo/index.vue?macro=true";
import { default as rtujZgnN6YAqCMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/SpecInfo/rtu.vue?macro=true";
import { default as specsVnW9RMf9fMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/SpecInfo/spec.vue?macro=true";
import { default as geooVDzKtq4FUMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo.vue?macro=true";
import { default as installGrmTWq2WX1Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstallInfo/install.vue?macro=true";
import { default as managerRQTu48wPebMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstallInfo/manager.vue?macro=true";
import { default as InstallInfo8TEH2TBtnDMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstallInfo.vue?macro=true";
import { default as chartZH6n81fAyTMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstruInfo/chart.vue?macro=true";
import { default as nowCHcuNtAsz3Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstruInfo/now.vue?macro=true";
import { default as InstruInfovdatQDM8xHMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstruInfo.vue?macro=true";
import { default as indexmrfVMC8Bn8Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/SpecInfo/index.vue?macro=true";
import { default as rtu5YjuGu2YjsMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/SpecInfo/rtu.vue?macro=true";
import { default as specilFeLOr2BFMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/SpecInfo/spec.vue?macro=true";
import { default as pvWHeYdu0b0TMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv.vue?macro=true";
import { default as installTo35ZBuvEUMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstallInfo/install.vue?macro=true";
import { default as managerlgCzQtVvaAMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstallInfo/manager.vue?macro=true";
import { default as InstallInfoF3vjkwMdJSMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstallInfo.vue?macro=true";
import { default as charteK4kUwvptkMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstruInfo/chart.vue?macro=true";
import { default as indexD9usTemVAKMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstruInfo/index.vue?macro=true";
import { default as nowwHShuCArgoMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstruInfo/now.vue?macro=true";
import { default as InstruInfoOH3pnl4wNtMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstruInfo.vue?macro=true";
import { default as indexmYSm1cpGbCMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/SpecInfo/index.vue?macro=true";
import { default as rtujCfFC8SSUZMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/SpecInfo/rtu.vue?macro=true";
import { default as specZyOcXnGsIQMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/SpecInfo/spec.vue?macro=true";
import { default as solarheatmlEwVR9u2TMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat.vue?macro=true";
import { default as index8lxXyoJ8gAMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstallInfo/index.vue?macro=true";
import { default as InstallInfoG4P0Qp7yN5Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstallInfo.vue?macro=true";
import { default as chart4r39lcEQ9QMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstruInfo/chart.vue?macro=true";
import { default as nowhIWUFXs5UtMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstruInfo/now.vue?macro=true";
import { default as InstruInfocamJoSJ8vhMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstruInfo.vue?macro=true";
import { default as indexURdHzxykCmMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/index.vue?macro=true";
import { default as wf7b26iRFsCZMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf.vue?macro=true";
import { default as equipInfoSISOIDyKUUMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo.vue?macro=true";
import { default as ALLHLVZZYoYjQMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/ALL.vue?macro=true";
import { default as Failover9Xw5YwmLMaMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/Failover.vue?macro=true";
import { default as Failure8cHCFLBfQhMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/Failure.vue?macro=true";
import { default as allFhVeZOYxPgMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/all.vue?macro=true";
import { default as eventHistory6G1YDZzZxKMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/eventHistory.vue?macro=true";
import { default as rtuEventyYZTL3ahKJMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/rtuEvent.vue?macro=true";
import { default as eventDrsXqeTk84Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event.vue?macro=true";
import { default as essiaNcxi29AeMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/ess.vue?macro=true";
import { default as fuelcellz3uRSTUVsmMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/fuelcell.vue?macro=true";
import { default as geooGTj4GtMHcMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/geo.vue?macro=true";
import { default as pvCAhXu0jCClMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/pv.vue?macro=true";
import { default as solarheatFCjlTTgZs1Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/solarheat.vue?macro=true";
import { default as wf3PgqXCuB6nMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/wf.vue?macro=true";
import { default as monitoringDwli4kO3aDMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring.vue?macro=true";
import { default as monitoring_testu0uBB3NnzxMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring_test.vue?macro=true";
import { default as InstallInfoMUf0xJemPcMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/InstallInfo.vue?macro=true";
import { default as contractsvRpR09OJlfMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/contracts.vue?macro=true";
import { default as geo8n7f9wmsLVMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/indicators/geo.vue?macro=true";
import { default as pvIQptmRxZyeMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/indicators/pv.vue?macro=true";
import { default as solarheatnjvUR5DP2UMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/indicators/solarheat.vue?macro=true";
import { default as indicatorsgr88yl3uFbMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/indicators.vue?macro=true";
import { default as menuUtduDzeHcaMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/menu.vue?macro=true";
import { default as _91noticeid_93cJSmDUDQObMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/menuToNotice/[noticeid].vue?macro=true";
import { default as ownerInfozndGrdBBKdMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/ownerInfo.vue?macro=true";
import { default as predictedPowerwQvvtZlYmjMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/predictedPower.vue?macro=true";
import { default as siteInfohr6qT9l7RMMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/siteInfo.vue?macro=true";
import { default as morewXYIkiJ050Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more.vue?macro=true";
import { default as ess4gguq3ln0vMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/ess.vue?macro=true";
import { default as fuelcellZ77xo1pKqQMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/fuelcell.vue?macro=true";
import { default as geooYxYKi7pzFMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/geo.vue?macro=true";
import { default as pvvTJ5ZibQ0QMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/pv.vue?macro=true";
import { default as solarheatMCzoKVe6aZMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/solarheat.vue?macro=true";
import { default as wfY7PfkC8fFkMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/wf.vue?macro=true";
import { default as powerGenerationpE5wQfTEDQMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration.vue?macro=true";
import { default as geot5V8oyafy7Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/report/geo.vue?macro=true";
import { default as pvzR069JKladMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/report/pv.vue?macro=true";
import { default as solarheatWAM95BnCXIMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/report/solarheat.vue?macro=true";
import { default as reportjDeY8U8pSaMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/report.vue?macro=true";
import { default as pvSWt4qbzC2eMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/reportPDF/pv.vue?macro=true";
import { default as reportPDF3xJzJUiVW8Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/reportPDF.vue?macro=true";
import { default as statusnlEYHyHU4nMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/status.vue?macro=true";
import { default as viewsjsOIarrqfOMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views.vue?macro=true";
import { default as _91id_93zt2PHYf3ZaMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id].vue?macro=true";
import { default as doneFindidDQ2fRBCvvQMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/finduser/doneFindid.vue?macro=true";
import { default as doneFindpasskxUsQDQdWlMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/finduser/doneFindpass.vue?macro=true";
import { default as findidaCu47FWQ5IMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/finduser/findid.vue?macro=true";
import { default as findpassuNB2Ycdtv0Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/finduser/findpass.vue?macro=true";
import { default as finduserbZCO25FgXTMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/finduser.vue?macro=true";
import { default as intro3X1WIFuggrMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/intro.vue?macro=true";
import { default as loginDwbC2bNQ6kMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/login.vue?macro=true";
import { default as logoutb49oDmIa3CMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/logout.vue?macro=true";
import { default as personTermsukVGHBei83Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/signin/personTerms.vue?macro=true";
import { default as termff2DNPAjbSMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/signin/term.vue?macro=true";
import { default as useTermsqaFyLe8steMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/signin/useTerms.vue?macro=true";
import { default as signinkvbpdwSdO2Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/signin.vue?macro=true";
import { default as useriHbWZk1z41Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user.vue?macro=true";
import { default as mobilel41wG6mxVaMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile.vue?macro=true";
import { default as swagger_45uiqU4jv7mnbHMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/swagger-ui.vue?macro=true";
import { default as login2mTmulNEouMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/test/login.vue?macro=true";
import { default as mainMNevBwmk2TMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/test/main.vue?macro=true";
import { default as compare_45ownerQAaGlSAWdoMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/analysis/compare-owner.vue?macro=true";
import { default as compare_45regionr1tOdvQWI5Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/analysis/compare-region.vue?macro=true";
import { default as geowU5P0hw3NsMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/analysis/component/geo.vue?macro=true";
import { default as ownerSearchModalxD3f5pF6vCMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/analysis/component/ownerSearchModal.vue?macro=true";
import { default as pvGjXyzWvV1jMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/analysis/component/pv.vue?macro=true";
import { default as solarheatR8FiQrGmC3Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/analysis/component/solarheat.vue?macro=true";
import { default as ownerXuuCW5GeM4Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/analysis/owner.vue?macro=true";
import { default as analysisLhngEOq592Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/analysis.vue?macro=true";
import { default as Energy_45ListrG7jvuHLaKMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/Dashboard/Energy-List.vue?macro=true";
import { default as card_45base7LvAlatVyLMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/Dashboard/card-base.vue?macro=true";
import { default as energy_45cardmN11umJ0pjMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/Dashboard/energy-card.vue?macro=true";
import { default as energy_45card2Tl480SuqaeMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/Dashboard/energy-card2.vue?macro=true";
import { default as info_45card89vfZUAWFIMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/Dashboard/info-card.vue?macro=true";
import { default as link_45rate_45cardCYaFqq6HM3Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/Dashboard/link-rate-card.vue?macro=true";
import { default as FileUpload7vxK6neCnDMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/FileUpload.vue?macro=true";
import { default as index2fj6gi8FgfMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/InstallInfo/index.vue?macro=true";
import { default as pvaSGDJDlWE4Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/InstallInfo/pv.vue?macro=true";
import { default as solarheat0agNLKNnBZMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/InstallInfo/solarheat.vue?macro=true";
import { default as pvspAthVFSgMMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/InstruInfo/pv.vue?macro=true";
import { default as indexkmIioBd425Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/ManagerInfo/index.vue?macro=true";
import { default as pvKev74EBbcFMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/ManagerInfo/pv.vue?macro=true";
import { default as solarheat1LwCCRIJ3rMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/ManagerInfo/solarheat.vue?macro=true";
import { default as pvvtp58yu1nhMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/ManagerInfo/pv.vue?macro=true";
import { default as solarheatHyLoXHbH66Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/ManagerInfo/solarheat.vue?macro=true";
import { default as indexM8aV5Y6abIMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/index.vue?macro=true";
import { default as pv6ihKZJgf4PMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/pv.vue?macro=true";
import { default as solarqTEmUesK3qMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/solar.vue?macro=true";
import { default as index2RKdZk6KYdMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/RtuInfo/index.vue?macro=true";
import { default as common_45code_45dropdownLliBTgEOVgMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/SearchBox/common-code-dropdown.vue?macro=true";
import { default as date_45set_45reportwAMfAgSDnpMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/SearchBox/date-set-report.vue?macro=true";
import { default as date_45setOvdaxjHWnlMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/SearchBox/date-set.vue?macro=true";
import { default as region_45sethRGcN7YnFIMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/SearchBox/region-set.vue?macro=true";
import { default as geo8RsEQPVCjDMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/SpecInfo/geo.vue?macro=true";
import { default as pvc6gZsGac0QMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/SpecInfo/pv.vue?macro=true";
import { default as solarheatIPTWbrFN0mMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/SpecInfo/solarheat.vue?macro=true";
import { default as Table_45BoxZbCGGSfDwOMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/Table-Box.vue?macro=true";
import { default as api_45search_45boxfWui6l2fZfMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/api-search-box.vue?macro=true";
import { default as data_45tabledmvFjXegcGMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/data-table.vue?macro=true";
import { default as event_45search_45gridNQ8N4Yz6NfMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/event-search-grid.vue?macro=true";
import { default as info_45boxouaJ7s4uMkMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/info-box.vue?macro=true";
import { default as region_45selectYeKmKNwtPWMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/region-select.vue?macro=true";
import { default as search_45boxF1NuhGzNU2Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/search-box.vue?macro=true";
import { default as table_45pagingEZnpWglr5FMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/table-paging.vue?macro=true";
import { default as toc_45templateR7oHdTxjPhMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/template/toc-template.vue?macro=true";
import { default as test_45gridHoBG7F9qZvMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/test-grid.vue?macro=true";
import { default as _91id_93vyP2CvtFEYMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/faq/[id].vue?macro=true";
import { default as indexdXwKHOhMnzMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/faq/index.vue?macro=true";
import { default as faq_companyCR3fq4er2HMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/faq_company.vue?macro=true";
import { default as login_45termsOHiSZJtX4WMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/login-terms.vue?macro=true";
import { default as _91id_93lGovtSJg8LMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/notice/[id].vue?macro=true";
import { default as indexNEjvbBKgR4Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/notice/index.vue?macro=true";
import { default as noticewVG9ZFG85gMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/notice.vue?macro=true";
import { default as _91id_937MmJQ2VgDUMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/qna/[id].vue?macro=true";
import { default as add5qs0JkSv0AMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/qna/add.vue?macro=true";
import { default as indexTvbZfBzJwyMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/qna/index.vue?macro=true";
import { default as qnacfD0xAvqV4Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/qna.vue?macro=true";
import { default as _91id_93Ep94w1MWrDMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/reference/[id].vue?macro=true";
import { default as indexJ2ymjz9EG7Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/reference/index.vue?macro=true";
import { default as referenceol19MYR9qoMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/reference.vue?macro=true";
import { default as terms1vQMjYNDkUVMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/terms-list/terms1.vue?macro=true";
import { default as terms23W00aClzfpMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/terms-list/terms2.vue?macro=true";
import { default as termsJnplQ2it8AMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/terms.vue?macro=true";
import { default as customer0wHDoLfcv4Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer.vue?macro=true";
import { default as geo_45prod_45card8yz7ylAqn7Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/geo-prod-card.vue?macro=true";
import { default as geo_45prod_45chartejNm1S1QSnMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/geo-prod-chart.vue?macro=true";
import { default as geo_45usage_45cardHte67zNvMbMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/geo-usage-card.vue?macro=true";
import { default as geo_45usage_45chartA5xXYkkxXCMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/geo-usage-chart.vue?macro=true";
import { default as raw_45data_45chartsfQmlbdGstMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/raw-data-chart.vue?macro=true";
import { default as raw_45data_45info5L1AfmOIGrMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/raw-data-info.vue?macro=true";
import { default as solarheat_45prod_45cardzJtOJiVRUeMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/solarheat-prod-card.vue?macro=true";
import { default as solarheat_45prod_45chartHZe7ORPNUhMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/solarheat-prod-chart.vue?macro=true";
import { default as solarheat_45usage_45card5i7yHJ1WxXMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/solarheat-usage-card.vue?macro=true";
import { default as solarheat_45usage_45chartvv62gSjsX5Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/solarheat-usage-chart.vue?macro=true";
import { default as geo_45prod90zOGjljcKMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/geo-prod.vue?macro=true";
import { default as geo_45usageuGqxsCVYF7Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/geo-usage.vue?macro=true";
import { default as geoXjr9VXC8exMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/geo.vue?macro=true";
import { default as pvsWtSpgEdLOMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/pv.vue?macro=true";
import { default as solarheat53UqMSrwG2Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/solarheat.vue?macro=true";
import { default as _91cid_93WhsLZAmFP4Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid].vue?macro=true";
import { default as equip_45detail6m5rPDWIbzMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail.vue?macro=true";
import { default as equip_45listqhuLKrB1fxMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-list.vue?macro=true";
import { default as equip_45mapoSaJa1j5XpMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-map.vue?macro=true";
import { default as measureNsXPe9DZUKMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/measure.vue?macro=true";
import { default as monitoring_45mapSLlqdGTL1zMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/monitoring-map.vue?macro=true";
import { default as indexwbQByYLJdTMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/power-generate/index.vue?macro=true";
import { default as power_45generatePwH6TMFduYMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/power-generate.vue?macro=true";
import { default as rtuV5p40wc4xkMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/rtu.vue?macro=true";
import { default as geoGW7u1KG1d5Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[newsitename]/geo.vue?macro=true";
import { default as pvrPURQF0JuGMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[newsitename]/pv.vue?macro=true";
import { default as solarheatKseStGWU0nMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[newsitename]/solarheat.vue?macro=true";
import { default as geoBbtbW6oG3GMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[sitename]/geo.vue?macro=true";
import { default as pv2ISMklEBdeMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[sitename]/pv.vue?macro=true";
import { default as solarheatU21zmbmKVMMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[sitename]/solarheat.vue?macro=true";
import { default as _91sitename_93RK7vfHzd4mMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[sitename].vue?macro=true";
import { default as indexrjB2LGVXoUMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/index.vue?macro=true";
import { default as _91newsitename_93lRvNG7vETpMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/test/[newsitename].vue?macro=true";
import { default as sitedx4SmCscPlMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site.vue?macro=true";
import { default as equipwNdz4pFSznMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip.vue?macro=true";
import { default as equipinfogasnucnT1FMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equipinfo.vue?macro=true";
import { default as errorTBtz4BCiMCMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/error.vue?macro=true";
import { default as _91eventId_93OxG43wCBM0Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/event/event-list/[eventId].vue?macro=true";
import { default as event_45listmvpsqQqsUKMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/event/event-list.vue?macro=true";
import { default as indexqVWcS7gTvfMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/event/index.vue?macro=true";
import { default as eventjn29opfZGQMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/event.vue?macro=true";
import { default as installWGzdkThAwhMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/install.vue?macro=true";
import { default as addw7gxbHTWR1Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/inventory/equip-company/add.vue?macro=true";
import { default as productumeMF0icl8Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/inventory/equip-company/product.vue?macro=true";
import { default as inventoryutabHcUwI2Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/inventory.vue?macro=true";
import { default as maintainencetl22VkvthDMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/maintainence.vue?macro=true";
import { default as add6heLm1X5UzMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/company/add.vue?macro=true";
import { default as indexoL1IrQZAf5Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/company/index.vue?macro=true";
import { default as companyGgkrWIydcuMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/company.vue?macro=true";
import { default as equip_45modelhLDTajjNsIMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/equip-model.vue?macro=true";
import { default as find_45owner2sX58bEqp6Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/equip/add/find-owner.vue?macro=true";
import { default as geo_45specwdHmLWi6f1Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/equip/add/geo-spec.vue?macro=true";
import { default as pv_45spec5iPy8lEa4rMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/equip/add/pv-spec.vue?macro=true";
import { default as solarheat_45specmqwrDhtppjMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/equip/add/solarheat-spec.vue?macro=true";
import { default as addpuvt7qmzbQMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/equip/add.vue?macro=true";
import { default as indexeAZYrA7qibMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/equip/index.vue?macro=true";
import { default as equip73nLHFSnMWMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/equip.vue?macro=true";
import { default as managementwSy3bVrvytMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/management.vue?macro=true";
import { default as indexyslYUEPh8oMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/manager/index.vue?macro=true";
import { default as notice4qbSkaslEIMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/notice.vue?macro=true";
import { default as addvj35zzO7fjMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/owner/add.vue?macro=true";
import { default as index1kYrLZxyy0Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/owner/index.vue?macro=true";
import { default as ownerUFVWmI18UXMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/owner.vue?macro=true";
import { default as indexUX2niuDhCAMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/permit/index.vue?macro=true";
import { default as permit297lFD8i8iMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/permit.vue?macro=true";
import { default as operatorjaDUPLZrqqMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/member/operator.vue?macro=true";
import { default as geo3AKNx6TU0iMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/member/owner-member/[id]/geo.vue?macro=true";
import { default as pv1WX2VGwqMcMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/member/owner-member/[id]/pv.vue?macro=true";
import { default as solarheatFgDMzNV9FoMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/member/owner-member/[id]/solarheat.vue?macro=true";
import { default as _91id_93QTS5yiF2aUMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/member/owner-member/[id].vue?macro=true";
import { default as owner_45registEsJquxEd7fMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/member/owner-member/owner-regist.vue?macro=true";
import { default as owner_45searchMCeZdNpixmMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/member/owner-member/owner-search.vue?macro=true";
import { default as ownerArWYccmI31Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/member/owner.vue?macro=true";
import { default as systemjnyZscqxVSMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/member/system.vue?macro=true";
import { default as memberqBk8UzFqhNMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/member.vue?macro=true";
import { default as alarm_45sendingF54OXBq0dIMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/message/alarm-sending.vue?macro=true";
import { default as email_45sendingeVO5rt1PC9Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/message/email-sending.vue?macro=true";
import { default as messageogaRHnEEYyMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/message.vue?macro=true";
import { default as comprehensive_45slideygFdxhGTB5Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/monitoring/comprehensive-slide.vue?macro=true";
import { default as dashboard7FgmyUq2NhMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/monitoring/dashboard.vue?macro=true";
import { default as equip_dashboardxgffREWwGKMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/monitoring/equip_dashboard.vue?macro=true";
import { default as newDashboardPd9FybYg0qMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/monitoring/newDashboard.vue?macro=true";
import { default as prod_45slideXiqFkuAn8yMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/monitoring/prod-slide.vue?macro=true";
import { default as temp_newDashboardzpD3Hq74EGMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/monitoring/temp_newDashboard.vue?macro=true";
import { default as monitoringM2fibkyTM0Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/monitoring.vue?macro=true";
import { default as not_45foundOgIxJQzcqMMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/not-found.vue?macro=true";
import { default as operate0IofEfiy1dMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/operate.vue?macro=true";
import { default as ownersNd1Q78yGldMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report/analyze-efficient/owners.vue?macro=true";
import { default as prjkindsIkIVANuebVMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report/analyze-efficient/prjkinds.vue?macro=true";
import { default as regionsV7NzJsAumeMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report/analyze-efficient/regions.vue?macro=true";
import { default as analyze_45efficientz2woU1E1UYMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report/analyze-efficient.vue?macro=true";
import { default as ownersujm05i3nS8Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report/analyze-product/owners.vue?macro=true";
import { default as prjkindskajS0wlKDYMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report/analyze-product/prjkinds.vue?macro=true";
import { default as regions1T9DX7u30mMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report/analyze-product/regions.vue?macro=true";
import { default as analyze_45productznon1gFymSMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report/analyze-product.vue?macro=true";
import { default as env_45factorZd55QjJn6lMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report/env-factor.vue?macro=true";
import { default as eventlTJx5OEyxYMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report/event.vue?macro=true";
import { default as predictsbjmCOnP3OMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report/predict.vue?macro=true";
import { default as productlp2MM1Lf28Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report/product.vue?macro=true";
import { default as reportRwYariHM2iMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report.vue?macro=true";
import { default as app_45version4BHOdH1s18Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/service-info/app-version.vue?macro=true";
import { default as service_45infoKev3UpoGfkMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/service-info.vue?macro=true";
import { default as testMKlcs1AMQ3Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/test.vue?macro=true";
import { default as manager_45info2khEkiqIuMMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user-service/manager-info.vue?macro=true";
import { default as confirm_45modalLN60Wa0hONMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user-service/modal/confirm-modal.vue?macro=true";
import { default as manager_45add_45modalx2Wd7mvxN4Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user-service/modal/manager-add-modal.vue?macro=true";
import { default as manager_45modify_45modaloeUWukCV6YMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user-service/modal/manager-modify-modal.vue?macro=true";
import { default as set_45manager_45all_45modalexXpsNy9nqMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user-service/modal/set-manager-all-modal.vue?macro=true";
import { default as set_45manager_45select_45modalgsdvq34nxIMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user-service/modal/set-manager-select-modal.vue?macro=true";
import { default as set_45manager1jV4Us1xD1Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user-service/set-manager.vue?macro=true";
import { default as user_45infouxi5dvWzXHMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user-service/user-info.vue?macro=true";
import { default as user_45settingdID9Lrt0HqMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user-service/user-setting.vue?macro=true";
import { default as user_45servicepyarq6WQ7OMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user-service.vue?macro=true";
import { default as find_45idpmYD4u5lqaMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user/find-id.vue?macro=true";
import { default as indexVzv8uNLVpxMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user/index.vue?macro=true";
import { default as loginYEsMGRJ2V8Meta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user/login.vue?macro=true";
import { default as tocwun8sciQGaMeta } from "/home/kbet/rems/kie-rems-mweb/src/client/pages/toc.vue?macro=true";
export default [
  {
    name: error9jA5pByeDiMeta?.name ?? "error",
    path: error9jA5pByeDiMeta?.path ?? "/error",
    meta: error9jA5pByeDiMeta || {},
    alias: error9jA5pByeDiMeta?.alias || [],
    redirect: error9jA5pByeDiMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/error.vue").then(m => m.default || m)
  },
  {
    name: indexYNa31WL3WOMeta?.name ?? "index",
    path: indexYNa31WL3WOMeta?.path ?? "/",
    meta: indexYNa31WL3WOMeta || {},
    alias: indexYNa31WL3WOMeta?.alias || [],
    redirect: indexYNa31WL3WOMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: mobilel41wG6mxVaMeta?.name ?? "mobile",
    path: mobilel41wG6mxVaMeta?.path ?? "/mobile",
    children: [
  {
    name: error_45pagev8Ult4y7PqMeta?.name ?? "mobile-error-page",
    path: error_45pagev8Ult4y7PqMeta?.path ?? "error-page",
    meta: error_45pagev8Ult4y7PqMeta || {},
    alias: error_45pagev8Ult4y7PqMeta?.alias || [],
    redirect: error_45pagev8Ult4y7PqMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/error-page.vue").then(m => m.default || m)
  },
  {
    name: none_45contents_45pageg1JRgBnqxWMeta?.name ?? "mobile-none-contents-page",
    path: none_45contents_45pageg1JRgBnqxWMeta?.path ?? "none-contents-page",
    meta: none_45contents_45pageg1JRgBnqxWMeta || {},
    alias: none_45contents_45pageg1JRgBnqxWMeta?.alias || [],
    redirect: none_45contents_45pageg1JRgBnqxWMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/none-contents-page.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyxObHJR1LteMeta?.name ?? "mobile-privacy-policy",
    path: privacy_45policyxObHJR1LteMeta?.path ?? "privacy-policy",
    meta: privacy_45policyxObHJR1LteMeta || {},
    alias: privacy_45policyxObHJR1LteMeta?.alias || [],
    redirect: privacy_45policyxObHJR1LteMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: usage_45policyH4q3GWWPx3Meta?.name ?? "mobile-usage-policy",
    path: usage_45policyH4q3GWWPx3Meta?.path ?? "usage-policy",
    meta: usage_45policyH4q3GWWPx3Meta || {},
    alias: usage_45policyH4q3GWWPx3Meta?.alias || [],
    redirect: usage_45policyH4q3GWWPx3Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/usage-policy.vue").then(m => m.default || m)
  },
  {
    name: useriHbWZk1z41Meta?.name ?? "mobile-user",
    path: useriHbWZk1z41Meta?.path ?? "user",
    children: [
  {
    path: _91id_93zt2PHYf3ZaMeta?.path ?? ":id",
    children: [
  {
    name: changePass8Kx2HN30J5Meta?.name ?? "mobile-user-id-changePass",
    path: changePass8Kx2HN30J5Meta?.path ?? "changePass",
    meta: changePass8Kx2HN30J5Meta || {},
    alias: changePass8Kx2HN30J5Meta?.alias || [],
    redirect: changePass8Kx2HN30J5Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/changePass.vue").then(m => m.default || m)
  },
  {
    name: csG9fBVJ8lEhMeta?.name ?? "mobile-user-id-cs",
    path: csG9fBVJ8lEhMeta?.path ?? "cs",
    children: [
  {
    name: _10_questionwTWgClE9JZMeta?.name ?? "mobile-user-id-cs-guide-company-10_question",
    path: _10_questionwTWgClE9JZMeta?.path ?? "guide/company/10_question",
    meta: _10_questionwTWgClE9JZMeta || {},
    alias: _10_questionwTWgClE9JZMeta?.alias || [],
    redirect: _10_questionwTWgClE9JZMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/10_question.vue").then(m => m.default || m)
  },
  {
    name: _1_serviceOverviewgVxMgyDgzjMeta?.name ?? "mobile-user-id-cs-guide-company-1_serviceOverview",
    path: _1_serviceOverviewgVxMgyDgzjMeta?.path ?? "guide/company/1_serviceOverview",
    meta: _1_serviceOverviewgVxMgyDgzjMeta || {},
    alias: _1_serviceOverviewgVxMgyDgzjMeta?.alias || [],
    redirect: _1_serviceOverviewgVxMgyDgzjMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/1_serviceOverview.vue").then(m => m.default || m)
  },
  {
    name: _2_statusFEqkIXKA0uMeta?.name ?? "mobile-user-id-cs-guide-company-2_status",
    path: _2_statusFEqkIXKA0uMeta?.path ?? "guide/company/2_status",
    meta: _2_statusFEqkIXKA0uMeta || {},
    alias: _2_statusFEqkIXKA0uMeta?.alias || [],
    redirect: _2_statusFEqkIXKA0uMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/2_status.vue").then(m => m.default || m)
  },
  {
    name: _3_equipSelectrWD0RFCFgtMeta?.name ?? "mobile-user-id-cs-guide-company-3_equipSelect",
    path: _3_equipSelectrWD0RFCFgtMeta?.path ?? "guide/company/3_equipSelect",
    meta: _3_equipSelectrWD0RFCFgtMeta || {},
    alias: _3_equipSelectrWD0RFCFgtMeta?.alias || [],
    redirect: _3_equipSelectrWD0RFCFgtMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/3_equipSelect.vue").then(m => m.default || m)
  },
  {
    name: _4_instruIfrLzVciqUMeta?.name ?? "mobile-user-id-cs-guide-company-4_instru",
    path: _4_instruIfrLzVciqUMeta?.path ?? "guide/company/4_instru",
    meta: _4_instruIfrLzVciqUMeta || {},
    alias: _4_instruIfrLzVciqUMeta?.alias || [],
    redirect: _4_instruIfrLzVciqUMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/4_instru.vue").then(m => m.default || m)
  },
  {
    name: _5_equipInfoVtzXb3MK2BMeta?.name ?? "mobile-user-id-cs-guide-company-5_equipInfo",
    path: _5_equipInfoVtzXb3MK2BMeta?.path ?? "guide/company/5_equipInfo",
    meta: _5_equipInfoVtzXb3MK2BMeta || {},
    alias: _5_equipInfoVtzXb3MK2BMeta?.alias || [],
    redirect: _5_equipInfoVtzXb3MK2BMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/5_equipInfo.vue").then(m => m.default || m)
  },
  {
    name: _6_eventmydCxbVGwgMeta?.name ?? "mobile-user-id-cs-guide-company-6_event",
    path: _6_eventmydCxbVGwgMeta?.path ?? "guide/company/6_event",
    meta: _6_eventmydCxbVGwgMeta || {},
    alias: _6_eventmydCxbVGwgMeta?.alias || [],
    redirect: _6_eventmydCxbVGwgMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/6_event.vue").then(m => m.default || m)
  },
  {
    name: _7_powerGenerationISLKZjMLjKMeta?.name ?? "mobile-user-id-cs-guide-company-7_powerGeneration",
    path: _7_powerGenerationISLKZjMLjKMeta?.path ?? "guide/company/7_powerGeneration",
    meta: _7_powerGenerationISLKZjMLjKMeta || {},
    alias: _7_powerGenerationISLKZjMLjKMeta?.alias || [],
    redirect: _7_powerGenerationISLKZjMLjKMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/7_powerGeneration.vue").then(m => m.default || m)
  },
  {
    name: _8_installInfo2yW9U5pnDIMeta?.name ?? "mobile-user-id-cs-guide-company-8_installInfo",
    path: _8_installInfo2yW9U5pnDIMeta?.path ?? "guide/company/8_installInfo",
    meta: _8_installInfo2yW9U5pnDIMeta || {},
    alias: _8_installInfo2yW9U5pnDIMeta?.alias || [],
    redirect: _8_installInfo2yW9U5pnDIMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/8_installInfo.vue").then(m => m.default || m)
  },
  {
    name: _9_ownerInfopNgxYBFxxsMeta?.name ?? "mobile-user-id-cs-guide-company-9_ownerInfo",
    path: _9_ownerInfopNgxYBFxxsMeta?.path ?? "guide/company/9_ownerInfo",
    meta: _9_ownerInfopNgxYBFxxsMeta || {},
    alias: _9_ownerInfopNgxYBFxxsMeta?.alias || [],
    redirect: _9_ownerInfopNgxYBFxxsMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/company/9_ownerInfo.vue").then(m => m.default || m)
  },
  {
    name: indexxbPDYlXL9wMeta?.name ?? "mobile-user-id-cs-guide",
    path: indexxbPDYlXL9wMeta?.path ?? "guide",
    meta: indexxbPDYlXL9wMeta || {},
    alias: indexxbPDYlXL9wMeta?.alias || [],
    redirect: indexxbPDYlXL9wMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/index.vue").then(m => m.default || m)
  },
  {
    name: _1_serviceOverviewD1ZPgKssYeMeta?.name ?? "mobile-user-id-cs-guide-owner-1_serviceOverview",
    path: _1_serviceOverviewD1ZPgKssYeMeta?.path ?? "guide/owner/1_serviceOverview",
    meta: _1_serviceOverviewD1ZPgKssYeMeta || {},
    alias: _1_serviceOverviewD1ZPgKssYeMeta?.alias || [],
    redirect: _1_serviceOverviewD1ZPgKssYeMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/1_serviceOverview.vue").then(m => m.default || m)
  },
  {
    name: _2_statusZG1c7dvUNGMeta?.name ?? "mobile-user-id-cs-guide-owner-2_status",
    path: _2_statusZG1c7dvUNGMeta?.path ?? "guide/owner/2_status",
    meta: _2_statusZG1c7dvUNGMeta || {},
    alias: _2_statusZG1c7dvUNGMeta?.alias || [],
    redirect: _2_statusZG1c7dvUNGMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/2_status.vue").then(m => m.default || m)
  },
  {
    name: _3_monitoring6yN5ch4oU3Meta?.name ?? "mobile-user-id-cs-guide-owner-3_monitoring",
    path: _3_monitoring6yN5ch4oU3Meta?.path ?? "guide/owner/3_monitoring",
    meta: _3_monitoring6yN5ch4oU3Meta || {},
    alias: _3_monitoring6yN5ch4oU3Meta?.alias || [],
    redirect: _3_monitoring6yN5ch4oU3Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/3_monitoring.vue").then(m => m.default || m)
  },
  {
    name: _4_equipInfo11pMwTWufhMeta?.name ?? "mobile-user-id-cs-guide-owner-4_equipInfo",
    path: _4_equipInfo11pMwTWufhMeta?.path ?? "guide/owner/4_equipInfo",
    meta: _4_equipInfo11pMwTWufhMeta || {},
    alias: _4_equipInfo11pMwTWufhMeta?.alias || [],
    redirect: _4_equipInfo11pMwTWufhMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/4_equipInfo.vue").then(m => m.default || m)
  },
  {
    name: _5_powerGenerationRmLmzEolZFMeta?.name ?? "mobile-user-id-cs-guide-owner-5_powerGeneration",
    path: _5_powerGenerationRmLmzEolZFMeta?.path ?? "guide/owner/5_powerGeneration",
    meta: _5_powerGenerationRmLmzEolZFMeta || {},
    alias: _5_powerGenerationRmLmzEolZFMeta?.alias || [],
    redirect: _5_powerGenerationRmLmzEolZFMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/5_powerGeneration.vue").then(m => m.default || m)
  },
  {
    name: _6_reportNAdVOG5VRVMeta?.name ?? "mobile-user-id-cs-guide-owner-6_report",
    path: _6_reportNAdVOG5VRVMeta?.path ?? "guide/owner/6_report",
    meta: _6_reportNAdVOG5VRVMeta || {},
    alias: _6_reportNAdVOG5VRVMeta?.alias || [],
    redirect: _6_reportNAdVOG5VRVMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/6_report.vue").then(m => m.default || m)
  },
  {
    name: _7_eventWfYn573MrUMeta?.name ?? "mobile-user-id-cs-guide-owner-7_event",
    path: _7_eventWfYn573MrUMeta?.path ?? "guide/owner/7_event",
    meta: _7_eventWfYn573MrUMeta || {},
    alias: _7_eventWfYn573MrUMeta?.alias || [],
    redirect: _7_eventWfYn573MrUMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/7_event.vue").then(m => m.default || m)
  },
  {
    name: _8_ownerInfos09VqtdsHBMeta?.name ?? "mobile-user-id-cs-guide-owner-8_ownerInfo",
    path: _8_ownerInfos09VqtdsHBMeta?.path ?? "guide/owner/8_ownerInfo",
    meta: _8_ownerInfos09VqtdsHBMeta || {},
    alias: _8_ownerInfos09VqtdsHBMeta?.alias || [],
    redirect: _8_ownerInfos09VqtdsHBMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/8_ownerInfo.vue").then(m => m.default || m)
  },
  {
    name: _9_questionfthUr3kdXeMeta?.name ?? "mobile-user-id-cs-guide-owner-9_question",
    path: _9_questionfthUr3kdXeMeta?.path ?? "guide/owner/9_question",
    meta: _9_questionfthUr3kdXeMeta || {},
    alias: _9_questionfthUr3kdXeMeta?.alias || [],
    redirect: _9_questionfthUr3kdXeMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/guide/owner/9_question.vue").then(m => m.default || m)
  },
  {
    name: mainAEoYuDlL5uMeta?.name ?? "mobile-user-id-cs-main",
    path: mainAEoYuDlL5uMeta?.path ?? "main",
    meta: mainAEoYuDlL5uMeta || {},
    alias: mainAEoYuDlL5uMeta?.alias || [],
    redirect: mainAEoYuDlL5uMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/main.vue").then(m => m.default || m)
  },
  {
    name: openSourceLnLSlcsdrgMeta?.name ?? "mobile-user-id-cs-openSource",
    path: openSourceLnLSlcsdrgMeta?.path ?? "openSource",
    meta: openSourceLnLSlcsdrgMeta || {},
    alias: openSourceLnLSlcsdrgMeta?.alias || [],
    redirect: openSourceLnLSlcsdrgMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/openSource.vue").then(m => m.default || m)
  },
  {
    name: personTermsJEiiistaadMeta?.name ?? "mobile-user-id-cs-personTerms",
    path: personTermsJEiiistaadMeta?.path ?? "personTerms",
    meta: personTermsJEiiistaadMeta || {},
    alias: personTermsJEiiistaadMeta?.alias || [],
    redirect: personTermsJEiiistaadMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/personTerms.vue").then(m => m.default || m)
  },
  {
    name: questiondAdAgTiTZ1Meta?.name ?? "mobile-user-id-cs-question",
    path: questiondAdAgTiTZ1Meta?.path ?? "question",
    meta: questiondAdAgTiTZ1Meta || {},
    alias: questiondAdAgTiTZ1Meta?.alias || [],
    redirect: questiondAdAgTiTZ1Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/question.vue").then(m => m.default || m)
  },
  {
    name: useTermsDuGXynngdGMeta?.name ?? "mobile-user-id-cs-useTerms",
    path: useTermsDuGXynngdGMeta?.path ?? "useTerms",
    meta: useTermsDuGXynngdGMeta || {},
    alias: useTermsDuGXynngdGMeta?.alias || [],
    redirect: useTermsDuGXynngdGMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs/useTerms.vue").then(m => m.default || m)
  }
],
    meta: csG9fBVJ8lEhMeta || {},
    alias: csG9fBVJ8lEhMeta?.alias || [],
    redirect: csG9fBVJ8lEhMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/cs.vue").then(m => m.default || m)
  },
  {
    name: equipListxAzaucVPzPMeta?.name ?? "mobile-user-id-equipList",
    path: equipListxAzaucVPzPMeta?.path ?? "equipList",
    meta: equipListxAzaucVPzPMeta || {},
    alias: equipListxAzaucVPzPMeta?.alias || [],
    redirect: equipListxAzaucVPzPMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/equipList.vue").then(m => m.default || m)
  },
  {
    name: equipMapIM4Khjs5gdMeta?.name ?? "mobile-user-id-equipMap",
    path: equipMapIM4Khjs5gdMeta?.path ?? "equipMap",
    meta: equipMapIM4Khjs5gdMeta || {},
    alias: equipMapIM4Khjs5gdMeta?.alias || [],
    redirect: equipMapIM4Khjs5gdMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/equipMap.vue").then(m => m.default || m)
  },
  {
    name: etcUFhSHPh4U9Meta?.name ?? "mobile-user-id-etc",
    path: etcUFhSHPh4U9Meta?.path ?? "etc",
    children: [
  {
    name: alarmZIujfY5imDMeta?.name ?? "mobile-user-id-etc-alarm",
    path: alarmZIujfY5imDMeta?.path ?? "alarm",
    children: [
  {
    name: alarmETCWJzdjZtMdRMeta?.name ?? "mobile-user-id-etc-alarm-alarmETC",
    path: alarmETCWJzdjZtMdRMeta?.path ?? "alarmETC",
    meta: alarmETCWJzdjZtMdRMeta || {},
    alias: alarmETCWJzdjZtMdRMeta?.alias || [],
    redirect: alarmETCWJzdjZtMdRMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm/alarmETC.vue").then(m => m.default || m)
  },
  {
    name: alarmOBSVzz8iDl8GIMeta?.name ?? "mobile-user-id-etc-alarm-alarmOBS",
    path: alarmOBSVzz8iDl8GIMeta?.path ?? "alarmOBS",
    meta: alarmOBSVzz8iDl8GIMeta || {},
    alias: alarmOBSVzz8iDl8GIMeta?.alias || [],
    redirect: alarmOBSVzz8iDl8GIMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm/alarmOBS.vue").then(m => m.default || m)
  },
  {
    name: alarmRTU9BRiivohnRMeta?.name ?? "mobile-user-id-etc-alarm-alarmRTU",
    path: alarmRTU9BRiivohnRMeta?.path ?? "alarmRTU",
    meta: alarmRTU9BRiivohnRMeta || {},
    alias: alarmRTU9BRiivohnRMeta?.alias || [],
    redirect: alarmRTU9BRiivohnRMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm/alarmRTU.vue").then(m => m.default || m)
  },
  {
    name: all9IPNBzpDjFMeta?.name ?? "mobile-user-id-etc-alarm-all",
    path: all9IPNBzpDjFMeta?.path ?? "all",
    meta: all9IPNBzpDjFMeta || {},
    alias: all9IPNBzpDjFMeta?.alias || [],
    redirect: all9IPNBzpDjFMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm/all.vue").then(m => m.default || m)
  }
],
    meta: alarmZIujfY5imDMeta || {},
    alias: alarmZIujfY5imDMeta?.alias || [],
    redirect: alarmZIujfY5imDMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/alarm.vue").then(m => m.default || m)
  },
  {
    path: noticewjkYbSxEWTMeta?.path ?? "notice",
    children: [
  {
    name: _91noticeid_93cYiHcz8KafMeta?.name ?? "mobile-user-id-etc-notice-noticeid",
    path: _91noticeid_93cYiHcz8KafMeta?.path ?? ":noticeid",
    meta: _91noticeid_93cYiHcz8KafMeta || {},
    alias: _91noticeid_93cYiHcz8KafMeta?.alias || [],
    redirect: _91noticeid_93cYiHcz8KafMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/notice/[noticeid].vue").then(m => m.default || m)
  },
  {
    name: indexgAuBlw28BWMeta?.name ?? "mobile-user-id-etc-notice",
    path: indexgAuBlw28BWMeta?.path ?? "",
    meta: indexgAuBlw28BWMeta || {},
    alias: indexgAuBlw28BWMeta?.alias || [],
    redirect: indexgAuBlw28BWMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/notice/index.vue").then(m => m.default || m)
  }
],
    name: noticewjkYbSxEWTMeta?.name ?? undefined,
    meta: noticewjkYbSxEWTMeta || {},
    alias: noticewjkYbSxEWTMeta?.alias || [],
    redirect: noticewjkYbSxEWTMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc/notice.vue").then(m => m.default || m)
  }
],
    meta: etcUFhSHPh4U9Meta || {},
    alias: etcUFhSHPh4U9Meta?.alias || [],
    redirect: etcUFhSHPh4U9Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/etc.vue").then(m => m.default || m)
  },
  {
    name: indexSSdr4XLR3kMeta?.name ?? "mobile-user-id",
    path: indexSSdr4XLR3kMeta?.path ?? "",
    meta: indexSSdr4XLR3kMeta || {},
    alias: indexSSdr4XLR3kMeta?.alias || [],
    redirect: indexSSdr4XLR3kMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: infot2y8IN4z4nMeta?.name ?? "mobile-user-id-info",
    path: infot2y8IN4z4nMeta?.path ?? "info",
    children: [
  {
    name: contact_45changeYrmtj18n2AMeta?.name ?? "mobile-user-id-info-contact-change",
    path: contact_45changeYrmtj18n2AMeta?.path ?? "contact-change",
    meta: contact_45changeYrmtj18n2AMeta || {},
    alias: contact_45changeYrmtj18n2AMeta?.alias || [],
    redirect: contact_45changeYrmtj18n2AMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/info/contact-change.vue").then(m => m.default || m)
  },
  {
    name: mainMDIRkpVqwjMeta?.name ?? "mobile-user-id-info-main",
    path: mainMDIRkpVqwjMeta?.path ?? "main",
    meta: mainMDIRkpVqwjMeta || {},
    alias: mainMDIRkpVqwjMeta?.alias || [],
    redirect: mainMDIRkpVqwjMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/info/main.vue").then(m => m.default || m)
  },
  {
    name: pw_45change47OCzxeKxPMeta?.name ?? "mobile-user-id-info-pw-change",
    path: pw_45change47OCzxeKxPMeta?.path ?? "pw-change",
    meta: pw_45change47OCzxeKxPMeta || {},
    alias: pw_45change47OCzxeKxPMeta?.alias || [],
    redirect: pw_45change47OCzxeKxPMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/info/pw-change.vue").then(m => m.default || m)
  },
  {
    name: user_45delete1qpQvFuIZvMeta?.name ?? "mobile-user-id-info-user-delete",
    path: user_45delete1qpQvFuIZvMeta?.path ?? "user-delete",
    meta: user_45delete1qpQvFuIZvMeta || {},
    alias: user_45delete1qpQvFuIZvMeta?.alias || [],
    redirect: user_45delete1qpQvFuIZvMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/info/user-delete.vue").then(m => m.default || m)
  }
],
    meta: infot2y8IN4z4nMeta || {},
    alias: infot2y8IN4z4nMeta?.alias || [],
    redirect: infot2y8IN4z4nMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/info.vue").then(m => m.default || m)
  },
  {
    name: settingIWRx8jNp0SMeta?.name ?? "mobile-user-id-setting",
    path: settingIWRx8jNp0SMeta?.path ?? "setting",
    meta: settingIWRx8jNp0SMeta || {},
    alias: settingIWRx8jNp0SMeta?.alias || [],
    redirect: settingIWRx8jNp0SMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/setting.vue").then(m => m.default || m)
  },
  {
    name: siteListErxvUNtd33Meta?.name ?? "mobile-user-id-siteList",
    path: siteListErxvUNtd33Meta?.path ?? "siteList",
    meta: siteListErxvUNtd33Meta || {},
    alias: siteListErxvUNtd33Meta?.alias || [],
    redirect: siteListErxvUNtd33Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/siteList.vue").then(m => m.default || m)
  },
  {
    name: viewsjsOIarrqfOMeta?.name ?? "mobile-user-id-views",
    path: viewsjsOIarrqfOMeta?.path ?? "views",
    children: [
  {
    name: FailoverEditywvWSbyyjTMeta?.name ?? "mobile-user-id-views-FailoverEdit",
    path: FailoverEditywvWSbyyjTMeta?.path ?? "FailoverEdit",
    meta: FailoverEditywvWSbyyjTMeta || {},
    alias: FailoverEditywvWSbyyjTMeta?.alias || [],
    redirect: FailoverEditywvWSbyyjTMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/FailoverEdit.vue").then(m => m.default || m)
  },
  {
    name: equipInfoSISOIDyKUUMeta?.name ?? "mobile-user-id-views-equipInfo",
    path: equipInfoSISOIDyKUUMeta?.path ?? "equipInfo",
    children: [
  {
    path: esscvYXgtP2JCMeta?.path ?? "company/ess",
    children: [
  {
    path: InstallInfoSwhfJ4t0wWMeta?.path ?? "InstallInfo",
    children: [
  {
    name: indexgkjP3sEZOrMeta?.name ?? "mobile-user-id-views-equipInfo-company-ess-InstallInfo",
    path: indexgkjP3sEZOrMeta?.path ?? "",
    meta: indexgkjP3sEZOrMeta || {},
    alias: indexgkjP3sEZOrMeta?.alias || [],
    redirect: indexgkjP3sEZOrMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstallInfo/index.vue").then(m => m.default || m)
  }
],
    name: InstallInfoSwhfJ4t0wWMeta?.name ?? undefined,
    meta: InstallInfoSwhfJ4t0wWMeta || {},
    alias: InstallInfoSwhfJ4t0wWMeta?.alias || [],
    redirect: InstallInfoSwhfJ4t0wWMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfohfGQzBiCr6Meta?.name ?? "mobile-user-id-views-equipInfo-company-ess-InstruInfo",
    path: InstruInfohfGQzBiCr6Meta?.path ?? "InstruInfo",
    children: [
  {
    name: chartFTGbIZmMM3Meta?.name ?? "mobile-user-id-views-equipInfo-company-ess-InstruInfo-chart",
    path: chartFTGbIZmMM3Meta?.path ?? "chart",
    meta: chartFTGbIZmMM3Meta || {},
    alias: chartFTGbIZmMM3Meta?.alias || [],
    redirect: chartFTGbIZmMM3Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowaJi6loUeHuMeta?.name ?? "mobile-user-id-views-equipInfo-company-ess-InstruInfo-now",
    path: nowaJi6loUeHuMeta?.path ?? "now",
    meta: nowaJi6loUeHuMeta || {},
    alias: nowaJi6loUeHuMeta?.alias || [],
    redirect: nowaJi6loUeHuMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfohfGQzBiCr6Meta || {},
    alias: InstruInfohfGQzBiCr6Meta?.alias || [],
    redirect: InstruInfohfGQzBiCr6Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: indexCihVb1PGAeMeta?.name ?? "mobile-user-id-views-equipInfo-company-ess",
    path: indexCihVb1PGAeMeta?.path ?? "",
    meta: indexCihVb1PGAeMeta || {},
    alias: indexCihVb1PGAeMeta?.alias || [],
    redirect: indexCihVb1PGAeMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess/index.vue").then(m => m.default || m)
  }
],
    name: esscvYXgtP2JCMeta?.name ?? undefined,
    meta: esscvYXgtP2JCMeta || {},
    alias: esscvYXgtP2JCMeta?.alias || [],
    redirect: esscvYXgtP2JCMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/ess.vue").then(m => m.default || m)
  },
  {
    path: fuelcelltHCJR5hHyfMeta?.path ?? "company/fuelcell",
    children: [
  {
    path: InstallInforg72yAKC9HMeta?.path ?? "InstallInfo",
    children: [
  {
    name: index0IgzdQPccoMeta?.name ?? "mobile-user-id-views-equipInfo-company-fuelcell-InstallInfo",
    path: index0IgzdQPccoMeta?.path ?? "",
    meta: index0IgzdQPccoMeta || {},
    alias: index0IgzdQPccoMeta?.alias || [],
    redirect: index0IgzdQPccoMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstallInfo/index.vue").then(m => m.default || m)
  }
],
    name: InstallInforg72yAKC9HMeta?.name ?? undefined,
    meta: InstallInforg72yAKC9HMeta || {},
    alias: InstallInforg72yAKC9HMeta?.alias || [],
    redirect: InstallInforg72yAKC9HMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfoGrp0P9BPjUMeta?.name ?? "mobile-user-id-views-equipInfo-company-fuelcell-InstruInfo",
    path: InstruInfoGrp0P9BPjUMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartiUYrsVgP94Meta?.name ?? "mobile-user-id-views-equipInfo-company-fuelcell-InstruInfo-chart",
    path: chartiUYrsVgP94Meta?.path ?? "chart",
    meta: chartiUYrsVgP94Meta || {},
    alias: chartiUYrsVgP94Meta?.alias || [],
    redirect: chartiUYrsVgP94Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowsd3vvhrzpVMeta?.name ?? "mobile-user-id-views-equipInfo-company-fuelcell-InstruInfo-now",
    path: nowsd3vvhrzpVMeta?.path ?? "now",
    meta: nowsd3vvhrzpVMeta || {},
    alias: nowsd3vvhrzpVMeta?.alias || [],
    redirect: nowsd3vvhrzpVMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfoGrp0P9BPjUMeta || {},
    alias: InstruInfoGrp0P9BPjUMeta?.alias || [],
    redirect: InstruInfoGrp0P9BPjUMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: indexwZymiGqByaMeta?.name ?? "mobile-user-id-views-equipInfo-company-fuelcell",
    path: indexwZymiGqByaMeta?.path ?? "",
    meta: indexwZymiGqByaMeta || {},
    alias: indexwZymiGqByaMeta?.alias || [],
    redirect: indexwZymiGqByaMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell/index.vue").then(m => m.default || m)
  }
],
    name: fuelcelltHCJR5hHyfMeta?.name ?? undefined,
    meta: fuelcelltHCJR5hHyfMeta || {},
    alias: fuelcelltHCJR5hHyfMeta?.alias || [],
    redirect: fuelcelltHCJR5hHyfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/fuelcell.vue").then(m => m.default || m)
  },
  {
    name: geoay8ugnngrAMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo",
    path: geoay8ugnngrAMeta?.path ?? "company/geo",
    children: [
  {
    name: InstallInfobLmovg4SLyMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo-InstallInfo",
    path: InstallInfobLmovg4SLyMeta?.path ?? "InstallInfo",
    children: [
  {
    name: installpcVtWr3LZFMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo-InstallInfo-install",
    path: installpcVtWr3LZFMeta?.path ?? "install",
    meta: installpcVtWr3LZFMeta || {},
    alias: installpcVtWr3LZFMeta?.alias || [],
    redirect: installpcVtWr3LZFMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstallInfo/install.vue").then(m => m.default || m)
  },
  {
    name: managerbKmvmmftNXMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo-InstallInfo-manager",
    path: managerbKmvmmftNXMeta?.path ?? "manager",
    meta: managerbKmvmmftNXMeta || {},
    alias: managerbKmvmmftNXMeta?.alias || [],
    redirect: managerbKmvmmftNXMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstallInfo/manager.vue").then(m => m.default || m)
  }
],
    meta: InstallInfobLmovg4SLyMeta || {},
    alias: InstallInfobLmovg4SLyMeta?.alias || [],
    redirect: InstallInfobLmovg4SLyMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfoXVBqd7kiJ0Meta?.name ?? "mobile-user-id-views-equipInfo-company-geo-InstruInfo",
    path: InstruInfoXVBqd7kiJ0Meta?.path ?? "InstruInfo",
    children: [
  {
    name: chartv0vP2TV0bBMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo-InstruInfo-chart",
    path: chartv0vP2TV0bBMeta?.path ?? "chart",
    meta: chartv0vP2TV0bBMeta || {},
    alias: chartv0vP2TV0bBMeta?.alias || [],
    redirect: chartv0vP2TV0bBMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowbUa0gspGlYMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo-InstruInfo-now",
    path: nowbUa0gspGlYMeta?.path ?? "now",
    meta: nowbUa0gspGlYMeta || {},
    alias: nowbUa0gspGlYMeta?.alias || [],
    redirect: nowbUa0gspGlYMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfoXVBqd7kiJ0Meta || {},
    alias: InstruInfoXVBqd7kiJ0Meta?.alias || [],
    redirect: InstruInfoXVBqd7kiJ0Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: SpecInfor5CujBj14qMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo-SpecInfo",
    path: SpecInfor5CujBj14qMeta?.path ?? "SpecInfo",
    children: [
  {
    name: rtuJz0xdvEaNRMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo-SpecInfo-rtu",
    path: rtuJz0xdvEaNRMeta?.path ?? "rtu",
    meta: rtuJz0xdvEaNRMeta || {},
    alias: rtuJz0xdvEaNRMeta?.alias || [],
    redirect: rtuJz0xdvEaNRMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/SpecInfo/rtu.vue").then(m => m.default || m)
  },
  {
    name: specIKhLrlJ48VMeta?.name ?? "mobile-user-id-views-equipInfo-company-geo-SpecInfo-spec",
    path: specIKhLrlJ48VMeta?.path ?? "spec",
    meta: specIKhLrlJ48VMeta || {},
    alias: specIKhLrlJ48VMeta?.alias || [],
    redirect: specIKhLrlJ48VMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/SpecInfo/spec.vue").then(m => m.default || m)
  }
],
    meta: SpecInfor5CujBj14qMeta || {},
    alias: SpecInfor5CujBj14qMeta?.alias || [],
    redirect: SpecInfor5CujBj14qMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo/SpecInfo.vue").then(m => m.default || m)
  }
],
    meta: geoay8ugnngrAMeta || {},
    alias: geoay8ugnngrAMeta?.alias || [],
    redirect: geoay8ugnngrAMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/geo.vue").then(m => m.default || m)
  },
  {
    name: pvmHSlG35iW6Meta?.name ?? "mobile-user-id-views-equipInfo-company-pv",
    path: pvmHSlG35iW6Meta?.path ?? "company/pv",
    children: [
  {
    name: InstallInfoNiUis5IdgvMeta?.name ?? "mobile-user-id-views-equipInfo-company-pv-InstallInfo",
    path: InstallInfoNiUis5IdgvMeta?.path ?? "InstallInfo",
    children: [
  {
    name: installcQ6lRWaLkjMeta?.name ?? "mobile-user-id-views-equipInfo-company-pv-InstallInfo-install",
    path: installcQ6lRWaLkjMeta?.path ?? "install",
    meta: installcQ6lRWaLkjMeta || {},
    alias: installcQ6lRWaLkjMeta?.alias || [],
    redirect: installcQ6lRWaLkjMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstallInfo/install.vue").then(m => m.default || m)
  },
  {
    name: manager9cbTTfBcneMeta?.name ?? "mobile-user-id-views-equipInfo-company-pv-InstallInfo-manager",
    path: manager9cbTTfBcneMeta?.path ?? "manager",
    meta: manager9cbTTfBcneMeta || {},
    alias: manager9cbTTfBcneMeta?.alias || [],
    redirect: manager9cbTTfBcneMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstallInfo/manager.vue").then(m => m.default || m)
  }
],
    meta: InstallInfoNiUis5IdgvMeta || {},
    alias: InstallInfoNiUis5IdgvMeta?.alias || [],
    redirect: InstallInfoNiUis5IdgvMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfoRhZ16bRPYpMeta?.name ?? "mobile-user-id-views-equipInfo-company-pv-InstruInfo",
    path: InstruInfoRhZ16bRPYpMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartwxqS5mFoz2Meta?.name ?? "mobile-user-id-views-equipInfo-company-pv-InstruInfo-chart",
    path: chartwxqS5mFoz2Meta?.path ?? "chart",
    meta: chartwxqS5mFoz2Meta || {},
    alias: chartwxqS5mFoz2Meta?.alias || [],
    redirect: chartwxqS5mFoz2Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowe82nssE6XJMeta?.name ?? "mobile-user-id-views-equipInfo-company-pv-InstruInfo-now",
    path: nowe82nssE6XJMeta?.path ?? "now",
    meta: nowe82nssE6XJMeta || {},
    alias: nowe82nssE6XJMeta?.alias || [],
    redirect: nowe82nssE6XJMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfoRhZ16bRPYpMeta || {},
    alias: InstruInfoRhZ16bRPYpMeta?.alias || [],
    redirect: InstruInfoRhZ16bRPYpMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: SpecInfonVqm8edHVfMeta?.name ?? "mobile-user-id-views-equipInfo-company-pv-SpecInfo",
    path: SpecInfonVqm8edHVfMeta?.path ?? "SpecInfo",
    children: [
  {
    name: rtuqE8BypqUbHMeta?.name ?? "mobile-user-id-views-equipInfo-company-pv-SpecInfo-rtu",
    path: rtuqE8BypqUbHMeta?.path ?? "rtu",
    meta: rtuqE8BypqUbHMeta || {},
    alias: rtuqE8BypqUbHMeta?.alias || [],
    redirect: rtuqE8BypqUbHMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/SpecInfo/rtu.vue").then(m => m.default || m)
  },
  {
    name: speci2Z6ZMqnrSMeta?.name ?? "mobile-user-id-views-equipInfo-company-pv-SpecInfo-spec",
    path: speci2Z6ZMqnrSMeta?.path ?? "spec",
    meta: speci2Z6ZMqnrSMeta || {},
    alias: speci2Z6ZMqnrSMeta?.alias || [],
    redirect: speci2Z6ZMqnrSMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/SpecInfo/spec.vue").then(m => m.default || m)
  }
],
    meta: SpecInfonVqm8edHVfMeta || {},
    alias: SpecInfonVqm8edHVfMeta?.alias || [],
    redirect: SpecInfonVqm8edHVfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv/SpecInfo.vue").then(m => m.default || m)
  }
],
    meta: pvmHSlG35iW6Meta || {},
    alias: pvmHSlG35iW6Meta?.alias || [],
    redirect: pvmHSlG35iW6Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatTZhrMPkVXkMeta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat",
    path: solarheatTZhrMPkVXkMeta?.path ?? "company/solarheat",
    children: [
  {
    name: InstallInfoTIKk7TkFNWMeta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-InstallInfo",
    path: InstallInfoTIKk7TkFNWMeta?.path ?? "InstallInfo",
    children: [
  {
    name: installznAQIpGrO0Meta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-InstallInfo-install",
    path: installznAQIpGrO0Meta?.path ?? "install",
    meta: installznAQIpGrO0Meta || {},
    alias: installznAQIpGrO0Meta?.alias || [],
    redirect: installznAQIpGrO0Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstallInfo/install.vue").then(m => m.default || m)
  },
  {
    name: managerxTB7esKWDKMeta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-InstallInfo-manager",
    path: managerxTB7esKWDKMeta?.path ?? "manager",
    meta: managerxTB7esKWDKMeta || {},
    alias: managerxTB7esKWDKMeta?.alias || [],
    redirect: managerxTB7esKWDKMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstallInfo/manager.vue").then(m => m.default || m)
  }
],
    meta: InstallInfoTIKk7TkFNWMeta || {},
    alias: InstallInfoTIKk7TkFNWMeta?.alias || [],
    redirect: InstallInfoTIKk7TkFNWMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfoaTpg21izIZMeta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-InstruInfo",
    path: InstruInfoaTpg21izIZMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartrQT8OSn5U0Meta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-InstruInfo-chart",
    path: chartrQT8OSn5U0Meta?.path ?? "chart",
    meta: chartrQT8OSn5U0Meta || {},
    alias: chartrQT8OSn5U0Meta?.alias || [],
    redirect: chartrQT8OSn5U0Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: now6ovcAH9yyhMeta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-InstruInfo-now",
    path: now6ovcAH9yyhMeta?.path ?? "now",
    meta: now6ovcAH9yyhMeta || {},
    alias: now6ovcAH9yyhMeta?.alias || [],
    redirect: now6ovcAH9yyhMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfoaTpg21izIZMeta || {},
    alias: InstruInfoaTpg21izIZMeta?.alias || [],
    redirect: InstruInfoaTpg21izIZMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: SpecInfo1nC2RBtRxyMeta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-SpecInfo",
    path: SpecInfo1nC2RBtRxyMeta?.path ?? "SpecInfo",
    children: [
  {
    name: rtuRfuTSOLxJdMeta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-SpecInfo-rtu",
    path: rtuRfuTSOLxJdMeta?.path ?? "rtu",
    meta: rtuRfuTSOLxJdMeta || {},
    alias: rtuRfuTSOLxJdMeta?.alias || [],
    redirect: rtuRfuTSOLxJdMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/SpecInfo/rtu.vue").then(m => m.default || m)
  },
  {
    name: spec1NAv2DyAyhMeta?.name ?? "mobile-user-id-views-equipInfo-company-solarheat-SpecInfo-spec",
    path: spec1NAv2DyAyhMeta?.path ?? "spec",
    meta: spec1NAv2DyAyhMeta || {},
    alias: spec1NAv2DyAyhMeta?.alias || [],
    redirect: spec1NAv2DyAyhMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/SpecInfo/spec.vue").then(m => m.default || m)
  }
],
    meta: SpecInfo1nC2RBtRxyMeta || {},
    alias: SpecInfo1nC2RBtRxyMeta?.alias || [],
    redirect: SpecInfo1nC2RBtRxyMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat/SpecInfo.vue").then(m => m.default || m)
  }
],
    meta: solarheatTZhrMPkVXkMeta || {},
    alias: solarheatTZhrMPkVXkMeta?.alias || [],
    redirect: solarheatTZhrMPkVXkMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/solarheat.vue").then(m => m.default || m)
  },
  {
    path: wfpCk5kbLvn8Meta?.path ?? "company/wf",
    children: [
  {
    path: InstallInfoX3d0eF4EfSMeta?.path ?? "InstallInfo",
    children: [
  {
    name: index3I7ymShIEcMeta?.name ?? "mobile-user-id-views-equipInfo-company-wf-InstallInfo",
    path: index3I7ymShIEcMeta?.path ?? "",
    meta: index3I7ymShIEcMeta || {},
    alias: index3I7ymShIEcMeta?.alias || [],
    redirect: index3I7ymShIEcMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstallInfo/index.vue").then(m => m.default || m)
  }
],
    name: InstallInfoX3d0eF4EfSMeta?.name ?? undefined,
    meta: InstallInfoX3d0eF4EfSMeta || {},
    alias: InstallInfoX3d0eF4EfSMeta?.alias || [],
    redirect: InstallInfoX3d0eF4EfSMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfoi3nLqP1NJxMeta?.name ?? "mobile-user-id-views-equipInfo-company-wf-InstruInfo",
    path: InstruInfoi3nLqP1NJxMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartJzFC9q90ATMeta?.name ?? "mobile-user-id-views-equipInfo-company-wf-InstruInfo-chart",
    path: chartJzFC9q90ATMeta?.path ?? "chart",
    meta: chartJzFC9q90ATMeta || {},
    alias: chartJzFC9q90ATMeta?.alias || [],
    redirect: chartJzFC9q90ATMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowxfzNcTr3qaMeta?.name ?? "mobile-user-id-views-equipInfo-company-wf-InstruInfo-now",
    path: nowxfzNcTr3qaMeta?.path ?? "now",
    meta: nowxfzNcTr3qaMeta || {},
    alias: nowxfzNcTr3qaMeta?.alias || [],
    redirect: nowxfzNcTr3qaMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfoi3nLqP1NJxMeta || {},
    alias: InstruInfoi3nLqP1NJxMeta?.alias || [],
    redirect: InstruInfoi3nLqP1NJxMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: indexGP4Fo4PFHyMeta?.name ?? "mobile-user-id-views-equipInfo-company-wf",
    path: indexGP4Fo4PFHyMeta?.path ?? "",
    meta: indexGP4Fo4PFHyMeta || {},
    alias: indexGP4Fo4PFHyMeta?.alias || [],
    redirect: indexGP4Fo4PFHyMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf/index.vue").then(m => m.default || m)
  }
],
    name: wfpCk5kbLvn8Meta?.name ?? undefined,
    meta: wfpCk5kbLvn8Meta || {},
    alias: wfpCk5kbLvn8Meta?.alias || [],
    redirect: wfpCk5kbLvn8Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/company/wf.vue").then(m => m.default || m)
  },
  {
    path: essEGKvXdJbF3Meta?.path ?? "owner/ess",
    children: [
  {
    path: InstallInfoN6KFtmzJAJMeta?.path ?? "InstallInfo",
    children: [
  {
    name: indexFJqiKG2vP1Meta?.name ?? "mobile-user-id-views-equipInfo-owner-ess-InstallInfo",
    path: indexFJqiKG2vP1Meta?.path ?? "",
    meta: indexFJqiKG2vP1Meta || {},
    alias: indexFJqiKG2vP1Meta?.alias || [],
    redirect: indexFJqiKG2vP1Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstallInfo/index.vue").then(m => m.default || m)
  }
],
    name: InstallInfoN6KFtmzJAJMeta?.name ?? undefined,
    meta: InstallInfoN6KFtmzJAJMeta || {},
    alias: InstallInfoN6KFtmzJAJMeta?.alias || [],
    redirect: InstallInfoN6KFtmzJAJMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfouk9ylZF9tZMeta?.name ?? "mobile-user-id-views-equipInfo-owner-ess-InstruInfo",
    path: InstruInfouk9ylZF9tZMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartOwoXBYgpvMMeta?.name ?? "mobile-user-id-views-equipInfo-owner-ess-InstruInfo-chart",
    path: chartOwoXBYgpvMMeta?.path ?? "chart",
    meta: chartOwoXBYgpvMMeta || {},
    alias: chartOwoXBYgpvMMeta?.alias || [],
    redirect: chartOwoXBYgpvMMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowqdWSTMUBAQMeta?.name ?? "mobile-user-id-views-equipInfo-owner-ess-InstruInfo-now",
    path: nowqdWSTMUBAQMeta?.path ?? "now",
    meta: nowqdWSTMUBAQMeta || {},
    alias: nowqdWSTMUBAQMeta?.alias || [],
    redirect: nowqdWSTMUBAQMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfouk9ylZF9tZMeta || {},
    alias: InstruInfouk9ylZF9tZMeta?.alias || [],
    redirect: InstruInfouk9ylZF9tZMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: indexWATFweR744Meta?.name ?? "mobile-user-id-views-equipInfo-owner-ess",
    path: indexWATFweR744Meta?.path ?? "",
    meta: indexWATFweR744Meta || {},
    alias: indexWATFweR744Meta?.alias || [],
    redirect: indexWATFweR744Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess/index.vue").then(m => m.default || m)
  }
],
    name: essEGKvXdJbF3Meta?.name ?? undefined,
    meta: essEGKvXdJbF3Meta || {},
    alias: essEGKvXdJbF3Meta?.alias || [],
    redirect: essEGKvXdJbF3Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/ess.vue").then(m => m.default || m)
  },
  {
    path: fuelcelloVE8gBaMRjMeta?.path ?? "owner/fuelcell",
    children: [
  {
    path: InstallInfoRRGdWEXvw2Meta?.path ?? "InstallInfo",
    children: [
  {
    name: indexxiPIjWSInVMeta?.name ?? "mobile-user-id-views-equipInfo-owner-fuelcell-InstallInfo",
    path: indexxiPIjWSInVMeta?.path ?? "",
    meta: indexxiPIjWSInVMeta || {},
    alias: indexxiPIjWSInVMeta?.alias || [],
    redirect: indexxiPIjWSInVMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstallInfo/index.vue").then(m => m.default || m)
  }
],
    name: InstallInfoRRGdWEXvw2Meta?.name ?? undefined,
    meta: InstallInfoRRGdWEXvw2Meta || {},
    alias: InstallInfoRRGdWEXvw2Meta?.alias || [],
    redirect: InstallInfoRRGdWEXvw2Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfojSDS3ucqO6Meta?.name ?? "mobile-user-id-views-equipInfo-owner-fuelcell-InstruInfo",
    path: InstruInfojSDS3ucqO6Meta?.path ?? "InstruInfo",
    children: [
  {
    name: chartvdX5eGanoCMeta?.name ?? "mobile-user-id-views-equipInfo-owner-fuelcell-InstruInfo-chart",
    path: chartvdX5eGanoCMeta?.path ?? "chart",
    meta: chartvdX5eGanoCMeta || {},
    alias: chartvdX5eGanoCMeta?.alias || [],
    redirect: chartvdX5eGanoCMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: now1ONIcdfiOfMeta?.name ?? "mobile-user-id-views-equipInfo-owner-fuelcell-InstruInfo-now",
    path: now1ONIcdfiOfMeta?.path ?? "now",
    meta: now1ONIcdfiOfMeta || {},
    alias: now1ONIcdfiOfMeta?.alias || [],
    redirect: now1ONIcdfiOfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfojSDS3ucqO6Meta || {},
    alias: InstruInfojSDS3ucqO6Meta?.alias || [],
    redirect: InstruInfojSDS3ucqO6Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: indexAW6D8zfI26Meta?.name ?? "mobile-user-id-views-equipInfo-owner-fuelcell",
    path: indexAW6D8zfI26Meta?.path ?? "",
    meta: indexAW6D8zfI26Meta || {},
    alias: indexAW6D8zfI26Meta?.alias || [],
    redirect: indexAW6D8zfI26Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell/index.vue").then(m => m.default || m)
  }
],
    name: fuelcelloVE8gBaMRjMeta?.name ?? undefined,
    meta: fuelcelloVE8gBaMRjMeta || {},
    alias: fuelcelloVE8gBaMRjMeta?.alias || [],
    redirect: fuelcelloVE8gBaMRjMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/fuelcell.vue").then(m => m.default || m)
  },
  {
    name: geooVDzKtq4FUMeta?.name ?? "mobile-user-id-views-equipInfo-owner-geo",
    path: geooVDzKtq4FUMeta?.path ?? "owner/geo",
    children: [
  {
    name: InstallInfo1D9BRzGyXlMeta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-InstallInfo",
    path: InstallInfo1D9BRzGyXlMeta?.path ?? "InstallInfo",
    children: [
  {
    name: installkZJRKwVRhvMeta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-InstallInfo-install",
    path: installkZJRKwVRhvMeta?.path ?? "install",
    meta: installkZJRKwVRhvMeta || {},
    alias: installkZJRKwVRhvMeta?.alias || [],
    redirect: installkZJRKwVRhvMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstallInfo/install.vue").then(m => m.default || m)
  },
  {
    name: manager9pgcVDxNPhMeta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-InstallInfo-manager",
    path: manager9pgcVDxNPhMeta?.path ?? "manager",
    meta: manager9pgcVDxNPhMeta || {},
    alias: manager9pgcVDxNPhMeta?.alias || [],
    redirect: manager9pgcVDxNPhMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstallInfo/manager.vue").then(m => m.default || m)
  }
],
    meta: InstallInfo1D9BRzGyXlMeta || {},
    alias: InstallInfo1D9BRzGyXlMeta?.alias || [],
    redirect: InstallInfo1D9BRzGyXlMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfoplUk5vZJ6OMeta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-InstruInfo",
    path: InstruInfoplUk5vZJ6OMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartA32HGfXD06Meta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-InstruInfo-chart",
    path: chartA32HGfXD06Meta?.path ?? "chart",
    meta: chartA32HGfXD06Meta || {},
    alias: chartA32HGfXD06Meta?.alias || [],
    redirect: chartA32HGfXD06Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowpUZ2fYhboPMeta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-InstruInfo-now",
    path: nowpUZ2fYhboPMeta?.path ?? "now",
    meta: nowpUZ2fYhboPMeta || {},
    alias: nowpUZ2fYhboPMeta?.alias || [],
    redirect: nowpUZ2fYhboPMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfoplUk5vZJ6OMeta || {},
    alias: InstruInfoplUk5vZJ6OMeta?.alias || [],
    redirect: InstruInfoplUk5vZJ6OMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: indexBWibM8nEBwMeta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-SpecInfo",
    path: indexBWibM8nEBwMeta?.path ?? "SpecInfo",
    meta: indexBWibM8nEBwMeta || {},
    alias: indexBWibM8nEBwMeta?.alias || [],
    redirect: indexBWibM8nEBwMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/SpecInfo/index.vue").then(m => m.default || m)
  },
  {
    name: rtujZgnN6YAqCMeta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-SpecInfo-rtu",
    path: rtujZgnN6YAqCMeta?.path ?? "SpecInfo/rtu",
    meta: rtujZgnN6YAqCMeta || {},
    alias: rtujZgnN6YAqCMeta?.alias || [],
    redirect: rtujZgnN6YAqCMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/SpecInfo/rtu.vue").then(m => m.default || m)
  },
  {
    name: specsVnW9RMf9fMeta?.name ?? "mobile-user-id-views-equipInfo-owner-geo-SpecInfo-spec",
    path: specsVnW9RMf9fMeta?.path ?? "SpecInfo/spec",
    meta: specsVnW9RMf9fMeta || {},
    alias: specsVnW9RMf9fMeta?.alias || [],
    redirect: specsVnW9RMf9fMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo/SpecInfo/spec.vue").then(m => m.default || m)
  }
],
    meta: geooVDzKtq4FUMeta || {},
    alias: geooVDzKtq4FUMeta?.alias || [],
    redirect: geooVDzKtq4FUMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/geo.vue").then(m => m.default || m)
  },
  {
    name: pvWHeYdu0b0TMeta?.name ?? "mobile-user-id-views-equipInfo-owner-pv",
    path: pvWHeYdu0b0TMeta?.path ?? "owner/pv",
    children: [
  {
    name: InstallInfo8TEH2TBtnDMeta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-InstallInfo",
    path: InstallInfo8TEH2TBtnDMeta?.path ?? "InstallInfo",
    children: [
  {
    name: installGrmTWq2WX1Meta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-InstallInfo-install",
    path: installGrmTWq2WX1Meta?.path ?? "install",
    meta: installGrmTWq2WX1Meta || {},
    alias: installGrmTWq2WX1Meta?.alias || [],
    redirect: installGrmTWq2WX1Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstallInfo/install.vue").then(m => m.default || m)
  },
  {
    name: managerRQTu48wPebMeta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-InstallInfo-manager",
    path: managerRQTu48wPebMeta?.path ?? "manager",
    meta: managerRQTu48wPebMeta || {},
    alias: managerRQTu48wPebMeta?.alias || [],
    redirect: managerRQTu48wPebMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstallInfo/manager.vue").then(m => m.default || m)
  }
],
    meta: InstallInfo8TEH2TBtnDMeta || {},
    alias: InstallInfo8TEH2TBtnDMeta?.alias || [],
    redirect: InstallInfo8TEH2TBtnDMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfovdatQDM8xHMeta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-InstruInfo",
    path: InstruInfovdatQDM8xHMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chartZH6n81fAyTMeta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-InstruInfo-chart",
    path: chartZH6n81fAyTMeta?.path ?? "chart",
    meta: chartZH6n81fAyTMeta || {},
    alias: chartZH6n81fAyTMeta?.alias || [],
    redirect: chartZH6n81fAyTMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowCHcuNtAsz3Meta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-InstruInfo-now",
    path: nowCHcuNtAsz3Meta?.path ?? "now",
    meta: nowCHcuNtAsz3Meta || {},
    alias: nowCHcuNtAsz3Meta?.alias || [],
    redirect: nowCHcuNtAsz3Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfovdatQDM8xHMeta || {},
    alias: InstruInfovdatQDM8xHMeta?.alias || [],
    redirect: InstruInfovdatQDM8xHMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: indexmrfVMC8Bn8Meta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-SpecInfo",
    path: indexmrfVMC8Bn8Meta?.path ?? "SpecInfo",
    meta: indexmrfVMC8Bn8Meta || {},
    alias: indexmrfVMC8Bn8Meta?.alias || [],
    redirect: indexmrfVMC8Bn8Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/SpecInfo/index.vue").then(m => m.default || m)
  },
  {
    name: rtu5YjuGu2YjsMeta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-SpecInfo-rtu",
    path: rtu5YjuGu2YjsMeta?.path ?? "SpecInfo/rtu",
    meta: rtu5YjuGu2YjsMeta || {},
    alias: rtu5YjuGu2YjsMeta?.alias || [],
    redirect: rtu5YjuGu2YjsMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/SpecInfo/rtu.vue").then(m => m.default || m)
  },
  {
    name: specilFeLOr2BFMeta?.name ?? "mobile-user-id-views-equipInfo-owner-pv-SpecInfo-spec",
    path: specilFeLOr2BFMeta?.path ?? "SpecInfo/spec",
    meta: specilFeLOr2BFMeta || {},
    alias: specilFeLOr2BFMeta?.alias || [],
    redirect: specilFeLOr2BFMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv/SpecInfo/spec.vue").then(m => m.default || m)
  }
],
    meta: pvWHeYdu0b0TMeta || {},
    alias: pvWHeYdu0b0TMeta?.alias || [],
    redirect: pvWHeYdu0b0TMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatmlEwVR9u2TMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat",
    path: solarheatmlEwVR9u2TMeta?.path ?? "owner/solarheat",
    children: [
  {
    name: InstallInfoF3vjkwMdJSMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-InstallInfo",
    path: InstallInfoF3vjkwMdJSMeta?.path ?? "InstallInfo",
    children: [
  {
    name: installTo35ZBuvEUMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-InstallInfo-install",
    path: installTo35ZBuvEUMeta?.path ?? "install",
    meta: installTo35ZBuvEUMeta || {},
    alias: installTo35ZBuvEUMeta?.alias || [],
    redirect: installTo35ZBuvEUMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstallInfo/install.vue").then(m => m.default || m)
  },
  {
    name: managerlgCzQtVvaAMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-InstallInfo-manager",
    path: managerlgCzQtVvaAMeta?.path ?? "manager",
    meta: managerlgCzQtVvaAMeta || {},
    alias: managerlgCzQtVvaAMeta?.alias || [],
    redirect: managerlgCzQtVvaAMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstallInfo/manager.vue").then(m => m.default || m)
  }
],
    meta: InstallInfoF3vjkwMdJSMeta || {},
    alias: InstallInfoF3vjkwMdJSMeta?.alias || [],
    redirect: InstallInfoF3vjkwMdJSMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstallInfo.vue").then(m => m.default || m)
  },
  {
    path: InstruInfoOH3pnl4wNtMeta?.path ?? "InstruInfo",
    children: [
  {
    name: charteK4kUwvptkMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-InstruInfo-chart",
    path: charteK4kUwvptkMeta?.path ?? "chart",
    meta: charteK4kUwvptkMeta || {},
    alias: charteK4kUwvptkMeta?.alias || [],
    redirect: charteK4kUwvptkMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: indexD9usTemVAKMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-InstruInfo",
    path: indexD9usTemVAKMeta?.path ?? "",
    meta: indexD9usTemVAKMeta || {},
    alias: indexD9usTemVAKMeta?.alias || [],
    redirect: indexD9usTemVAKMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstruInfo/index.vue").then(m => m.default || m)
  },
  {
    name: nowwHShuCArgoMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-InstruInfo-now",
    path: nowwHShuCArgoMeta?.path ?? "now",
    meta: nowwHShuCArgoMeta || {},
    alias: nowwHShuCArgoMeta?.alias || [],
    redirect: nowwHShuCArgoMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    name: InstruInfoOH3pnl4wNtMeta?.name ?? undefined,
    meta: InstruInfoOH3pnl4wNtMeta || {},
    alias: InstruInfoOH3pnl4wNtMeta?.alias || [],
    redirect: InstruInfoOH3pnl4wNtMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: indexmYSm1cpGbCMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-SpecInfo",
    path: indexmYSm1cpGbCMeta?.path ?? "SpecInfo",
    meta: indexmYSm1cpGbCMeta || {},
    alias: indexmYSm1cpGbCMeta?.alias || [],
    redirect: indexmYSm1cpGbCMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/SpecInfo/index.vue").then(m => m.default || m)
  },
  {
    name: rtujCfFC8SSUZMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-SpecInfo-rtu",
    path: rtujCfFC8SSUZMeta?.path ?? "SpecInfo/rtu",
    meta: rtujCfFC8SSUZMeta || {},
    alias: rtujCfFC8SSUZMeta?.alias || [],
    redirect: rtujCfFC8SSUZMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/SpecInfo/rtu.vue").then(m => m.default || m)
  },
  {
    name: specZyOcXnGsIQMeta?.name ?? "mobile-user-id-views-equipInfo-owner-solarheat-SpecInfo-spec",
    path: specZyOcXnGsIQMeta?.path ?? "SpecInfo/spec",
    meta: specZyOcXnGsIQMeta || {},
    alias: specZyOcXnGsIQMeta?.alias || [],
    redirect: specZyOcXnGsIQMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat/SpecInfo/spec.vue").then(m => m.default || m)
  }
],
    meta: solarheatmlEwVR9u2TMeta || {},
    alias: solarheatmlEwVR9u2TMeta?.alias || [],
    redirect: solarheatmlEwVR9u2TMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/solarheat.vue").then(m => m.default || m)
  },
  {
    path: wf7b26iRFsCZMeta?.path ?? "owner/wf",
    children: [
  {
    path: InstallInfoG4P0Qp7yN5Meta?.path ?? "InstallInfo",
    children: [
  {
    name: index8lxXyoJ8gAMeta?.name ?? "mobile-user-id-views-equipInfo-owner-wf-InstallInfo",
    path: index8lxXyoJ8gAMeta?.path ?? "",
    meta: index8lxXyoJ8gAMeta || {},
    alias: index8lxXyoJ8gAMeta?.alias || [],
    redirect: index8lxXyoJ8gAMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstallInfo/index.vue").then(m => m.default || m)
  }
],
    name: InstallInfoG4P0Qp7yN5Meta?.name ?? undefined,
    meta: InstallInfoG4P0Qp7yN5Meta || {},
    alias: InstallInfoG4P0Qp7yN5Meta?.alias || [],
    redirect: InstallInfoG4P0Qp7yN5Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: InstruInfocamJoSJ8vhMeta?.name ?? "mobile-user-id-views-equipInfo-owner-wf-InstruInfo",
    path: InstruInfocamJoSJ8vhMeta?.path ?? "InstruInfo",
    children: [
  {
    name: chart4r39lcEQ9QMeta?.name ?? "mobile-user-id-views-equipInfo-owner-wf-InstruInfo-chart",
    path: chart4r39lcEQ9QMeta?.path ?? "chart",
    meta: chart4r39lcEQ9QMeta || {},
    alias: chart4r39lcEQ9QMeta?.alias || [],
    redirect: chart4r39lcEQ9QMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstruInfo/chart.vue").then(m => m.default || m)
  },
  {
    name: nowhIWUFXs5UtMeta?.name ?? "mobile-user-id-views-equipInfo-owner-wf-InstruInfo-now",
    path: nowhIWUFXs5UtMeta?.path ?? "now",
    meta: nowhIWUFXs5UtMeta || {},
    alias: nowhIWUFXs5UtMeta?.alias || [],
    redirect: nowhIWUFXs5UtMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstruInfo/now.vue").then(m => m.default || m)
  }
],
    meta: InstruInfocamJoSJ8vhMeta || {},
    alias: InstruInfocamJoSJ8vhMeta?.alias || [],
    redirect: InstruInfocamJoSJ8vhMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/InstruInfo.vue").then(m => m.default || m)
  },
  {
    name: indexURdHzxykCmMeta?.name ?? "mobile-user-id-views-equipInfo-owner-wf",
    path: indexURdHzxykCmMeta?.path ?? "",
    meta: indexURdHzxykCmMeta || {},
    alias: indexURdHzxykCmMeta?.alias || [],
    redirect: indexURdHzxykCmMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf/index.vue").then(m => m.default || m)
  }
],
    name: wf7b26iRFsCZMeta?.name ?? undefined,
    meta: wf7b26iRFsCZMeta || {},
    alias: wf7b26iRFsCZMeta?.alias || [],
    redirect: wf7b26iRFsCZMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo/owner/wf.vue").then(m => m.default || m)
  }
],
    meta: equipInfoSISOIDyKUUMeta || {},
    alias: equipInfoSISOIDyKUUMeta?.alias || [],
    redirect: equipInfoSISOIDyKUUMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/equipInfo.vue").then(m => m.default || m)
  },
  {
    name: eventDrsXqeTk84Meta?.name ?? "mobile-user-id-views-event",
    path: eventDrsXqeTk84Meta?.path ?? "event",
    children: [
  {
    name: ALLHLVZZYoYjQMeta?.name ?? "mobile-user-id-views-event-ALL",
    path: ALLHLVZZYoYjQMeta?.path ?? "ALL",
    meta: ALLHLVZZYoYjQMeta || {},
    alias: ALLHLVZZYoYjQMeta?.alias || [],
    redirect: ALLHLVZZYoYjQMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/ALL.vue").then(m => m.default || m)
  },
  {
    name: Failover9Xw5YwmLMaMeta?.name ?? "mobile-user-id-views-event-Failover",
    path: Failover9Xw5YwmLMaMeta?.path ?? "Failover",
    meta: Failover9Xw5YwmLMaMeta || {},
    alias: Failover9Xw5YwmLMaMeta?.alias || [],
    redirect: Failover9Xw5YwmLMaMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/Failover.vue").then(m => m.default || m)
  },
  {
    name: Failure8cHCFLBfQhMeta?.name ?? "mobile-user-id-views-event-Failure",
    path: Failure8cHCFLBfQhMeta?.path ?? "Failure",
    meta: Failure8cHCFLBfQhMeta || {},
    alias: Failure8cHCFLBfQhMeta?.alias || [],
    redirect: Failure8cHCFLBfQhMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/Failure.vue").then(m => m.default || m)
  },
  {
    name: allFhVeZOYxPgMeta?.name ?? "mobile-user-id-views-event-all",
    path: allFhVeZOYxPgMeta?.path ?? "all",
    meta: allFhVeZOYxPgMeta || {},
    alias: allFhVeZOYxPgMeta?.alias || [],
    redirect: allFhVeZOYxPgMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/all.vue").then(m => m.default || m)
  },
  {
    name: eventHistory6G1YDZzZxKMeta?.name ?? "mobile-user-id-views-event-eventHistory",
    path: eventHistory6G1YDZzZxKMeta?.path ?? "eventHistory",
    meta: eventHistory6G1YDZzZxKMeta || {},
    alias: eventHistory6G1YDZzZxKMeta?.alias || [],
    redirect: eventHistory6G1YDZzZxKMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/eventHistory.vue").then(m => m.default || m)
  },
  {
    name: rtuEventyYZTL3ahKJMeta?.name ?? "mobile-user-id-views-event-rtuEvent",
    path: rtuEventyYZTL3ahKJMeta?.path ?? "rtuEvent",
    meta: rtuEventyYZTL3ahKJMeta || {},
    alias: rtuEventyYZTL3ahKJMeta?.alias || [],
    redirect: rtuEventyYZTL3ahKJMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event/rtuEvent.vue").then(m => m.default || m)
  }
],
    meta: eventDrsXqeTk84Meta || {},
    alias: eventDrsXqeTk84Meta?.alias || [],
    redirect: eventDrsXqeTk84Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/event.vue").then(m => m.default || m)
  },
  {
    name: monitoringDwli4kO3aDMeta?.name ?? "mobile-user-id-views-monitoring",
    path: monitoringDwli4kO3aDMeta?.path ?? "monitoring",
    children: [
  {
    name: essiaNcxi29AeMeta?.name ?? "mobile-user-id-views-monitoring-ess",
    path: essiaNcxi29AeMeta?.path ?? "ess",
    meta: essiaNcxi29AeMeta || {},
    alias: essiaNcxi29AeMeta?.alias || [],
    redirect: essiaNcxi29AeMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/ess.vue").then(m => m.default || m)
  },
  {
    name: fuelcellz3uRSTUVsmMeta?.name ?? "mobile-user-id-views-monitoring-fuelcell",
    path: fuelcellz3uRSTUVsmMeta?.path ?? "fuelcell",
    meta: fuelcellz3uRSTUVsmMeta || {},
    alias: fuelcellz3uRSTUVsmMeta?.alias || [],
    redirect: fuelcellz3uRSTUVsmMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/fuelcell.vue").then(m => m.default || m)
  },
  {
    name: geooGTj4GtMHcMeta?.name ?? "mobile-user-id-views-monitoring-geo",
    path: geooGTj4GtMHcMeta?.path ?? "geo",
    meta: geooGTj4GtMHcMeta || {},
    alias: geooGTj4GtMHcMeta?.alias || [],
    redirect: geooGTj4GtMHcMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/geo.vue").then(m => m.default || m)
  },
  {
    name: pvCAhXu0jCClMeta?.name ?? "mobile-user-id-views-monitoring-pv",
    path: pvCAhXu0jCClMeta?.path ?? "pv",
    meta: pvCAhXu0jCClMeta || {},
    alias: pvCAhXu0jCClMeta?.alias || [],
    redirect: pvCAhXu0jCClMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatFCjlTTgZs1Meta?.name ?? "mobile-user-id-views-monitoring-solarheat",
    path: solarheatFCjlTTgZs1Meta?.path ?? "solarheat",
    meta: solarheatFCjlTTgZs1Meta || {},
    alias: solarheatFCjlTTgZs1Meta?.alias || [],
    redirect: solarheatFCjlTTgZs1Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/solarheat.vue").then(m => m.default || m)
  },
  {
    name: wf3PgqXCuB6nMeta?.name ?? "mobile-user-id-views-monitoring-wf",
    path: wf3PgqXCuB6nMeta?.path ?? "wf",
    meta: wf3PgqXCuB6nMeta || {},
    alias: wf3PgqXCuB6nMeta?.alias || [],
    redirect: wf3PgqXCuB6nMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring/wf.vue").then(m => m.default || m)
  }
],
    meta: monitoringDwli4kO3aDMeta || {},
    alias: monitoringDwli4kO3aDMeta?.alias || [],
    redirect: monitoringDwli4kO3aDMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring.vue").then(m => m.default || m)
  },
  {
    name: monitoring_testu0uBB3NnzxMeta?.name ?? "mobile-user-id-views-monitoring_test",
    path: monitoring_testu0uBB3NnzxMeta?.path ?? "monitoring_test",
    meta: monitoring_testu0uBB3NnzxMeta || {},
    alias: monitoring_testu0uBB3NnzxMeta?.alias || [],
    redirect: monitoring_testu0uBB3NnzxMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/monitoring_test.vue").then(m => m.default || m)
  },
  {
    name: morewXYIkiJ050Meta?.name ?? "mobile-user-id-views-more",
    path: morewXYIkiJ050Meta?.path ?? "more",
    children: [
  {
    name: InstallInfoMUf0xJemPcMeta?.name ?? "mobile-user-id-views-more-InstallInfo",
    path: InstallInfoMUf0xJemPcMeta?.path ?? "InstallInfo",
    meta: InstallInfoMUf0xJemPcMeta || {},
    alias: InstallInfoMUf0xJemPcMeta?.alias || [],
    redirect: InstallInfoMUf0xJemPcMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/InstallInfo.vue").then(m => m.default || m)
  },
  {
    name: contractsvRpR09OJlfMeta?.name ?? "mobile-user-id-views-more-contracts",
    path: contractsvRpR09OJlfMeta?.path ?? "contracts",
    meta: contractsvRpR09OJlfMeta || {},
    alias: contractsvRpR09OJlfMeta?.alias || [],
    redirect: contractsvRpR09OJlfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/contracts.vue").then(m => m.default || m)
  },
  {
    name: indicatorsgr88yl3uFbMeta?.name ?? "mobile-user-id-views-more-indicators",
    path: indicatorsgr88yl3uFbMeta?.path ?? "indicators",
    children: [
  {
    name: geo8n7f9wmsLVMeta?.name ?? "mobile-user-id-views-more-indicators-geo",
    path: geo8n7f9wmsLVMeta?.path ?? "geo",
    meta: geo8n7f9wmsLVMeta || {},
    alias: geo8n7f9wmsLVMeta?.alias || [],
    redirect: geo8n7f9wmsLVMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/indicators/geo.vue").then(m => m.default || m)
  },
  {
    name: pvIQptmRxZyeMeta?.name ?? "mobile-user-id-views-more-indicators-pv",
    path: pvIQptmRxZyeMeta?.path ?? "pv",
    meta: pvIQptmRxZyeMeta || {},
    alias: pvIQptmRxZyeMeta?.alias || [],
    redirect: pvIQptmRxZyeMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/indicators/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatnjvUR5DP2UMeta?.name ?? "mobile-user-id-views-more-indicators-solarheat",
    path: solarheatnjvUR5DP2UMeta?.path ?? "solarheat",
    meta: solarheatnjvUR5DP2UMeta || {},
    alias: solarheatnjvUR5DP2UMeta?.alias || [],
    redirect: solarheatnjvUR5DP2UMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/indicators/solarheat.vue").then(m => m.default || m)
  }
],
    meta: indicatorsgr88yl3uFbMeta || {},
    alias: indicatorsgr88yl3uFbMeta?.alias || [],
    redirect: indicatorsgr88yl3uFbMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/indicators.vue").then(m => m.default || m)
  },
  {
    name: menuUtduDzeHcaMeta?.name ?? "mobile-user-id-views-more-menu",
    path: menuUtduDzeHcaMeta?.path ?? "menu",
    meta: menuUtduDzeHcaMeta || {},
    alias: menuUtduDzeHcaMeta?.alias || [],
    redirect: menuUtduDzeHcaMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/menu.vue").then(m => m.default || m)
  },
  {
    name: _91noticeid_93cJSmDUDQObMeta?.name ?? "mobile-user-id-views-more-menuToNotice-noticeid",
    path: _91noticeid_93cJSmDUDQObMeta?.path ?? "menuToNotice/:noticeid",
    meta: _91noticeid_93cJSmDUDQObMeta || {},
    alias: _91noticeid_93cJSmDUDQObMeta?.alias || [],
    redirect: _91noticeid_93cJSmDUDQObMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/menuToNotice/[noticeid].vue").then(m => m.default || m)
  },
  {
    name: ownerInfozndGrdBBKdMeta?.name ?? "mobile-user-id-views-more-ownerInfo",
    path: ownerInfozndGrdBBKdMeta?.path ?? "ownerInfo",
    meta: ownerInfozndGrdBBKdMeta || {},
    alias: ownerInfozndGrdBBKdMeta?.alias || [],
    redirect: ownerInfozndGrdBBKdMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/ownerInfo.vue").then(m => m.default || m)
  },
  {
    name: predictedPowerwQvvtZlYmjMeta?.name ?? "mobile-user-id-views-more-predictedPower",
    path: predictedPowerwQvvtZlYmjMeta?.path ?? "predictedPower",
    meta: predictedPowerwQvvtZlYmjMeta || {},
    alias: predictedPowerwQvvtZlYmjMeta?.alias || [],
    redirect: predictedPowerwQvvtZlYmjMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/predictedPower.vue").then(m => m.default || m)
  },
  {
    name: siteInfohr6qT9l7RMMeta?.name ?? "mobile-user-id-views-more-siteInfo",
    path: siteInfohr6qT9l7RMMeta?.path ?? "siteInfo",
    meta: siteInfohr6qT9l7RMMeta || {},
    alias: siteInfohr6qT9l7RMMeta?.alias || [],
    redirect: siteInfohr6qT9l7RMMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more/siteInfo.vue").then(m => m.default || m)
  }
],
    meta: morewXYIkiJ050Meta || {},
    alias: morewXYIkiJ050Meta?.alias || [],
    redirect: morewXYIkiJ050Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/more.vue").then(m => m.default || m)
  },
  {
    name: powerGenerationpE5wQfTEDQMeta?.name ?? "mobile-user-id-views-powerGeneration",
    path: powerGenerationpE5wQfTEDQMeta?.path ?? "powerGeneration",
    children: [
  {
    name: ess4gguq3ln0vMeta?.name ?? "mobile-user-id-views-powerGeneration-ess",
    path: ess4gguq3ln0vMeta?.path ?? "ess",
    meta: ess4gguq3ln0vMeta || {},
    alias: ess4gguq3ln0vMeta?.alias || [],
    redirect: ess4gguq3ln0vMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/ess.vue").then(m => m.default || m)
  },
  {
    name: fuelcellZ77xo1pKqQMeta?.name ?? "mobile-user-id-views-powerGeneration-fuelcell",
    path: fuelcellZ77xo1pKqQMeta?.path ?? "fuelcell",
    meta: fuelcellZ77xo1pKqQMeta || {},
    alias: fuelcellZ77xo1pKqQMeta?.alias || [],
    redirect: fuelcellZ77xo1pKqQMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/fuelcell.vue").then(m => m.default || m)
  },
  {
    name: geooYxYKi7pzFMeta?.name ?? "mobile-user-id-views-powerGeneration-geo",
    path: geooYxYKi7pzFMeta?.path ?? "geo",
    meta: geooYxYKi7pzFMeta || {},
    alias: geooYxYKi7pzFMeta?.alias || [],
    redirect: geooYxYKi7pzFMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/geo.vue").then(m => m.default || m)
  },
  {
    name: pvvTJ5ZibQ0QMeta?.name ?? "mobile-user-id-views-powerGeneration-pv",
    path: pvvTJ5ZibQ0QMeta?.path ?? "pv",
    meta: pvvTJ5ZibQ0QMeta || {},
    alias: pvvTJ5ZibQ0QMeta?.alias || [],
    redirect: pvvTJ5ZibQ0QMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatMCzoKVe6aZMeta?.name ?? "mobile-user-id-views-powerGeneration-solarheat",
    path: solarheatMCzoKVe6aZMeta?.path ?? "solarheat",
    meta: solarheatMCzoKVe6aZMeta || {},
    alias: solarheatMCzoKVe6aZMeta?.alias || [],
    redirect: solarheatMCzoKVe6aZMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/solarheat.vue").then(m => m.default || m)
  },
  {
    name: wfY7PfkC8fFkMeta?.name ?? "mobile-user-id-views-powerGeneration-wf",
    path: wfY7PfkC8fFkMeta?.path ?? "wf",
    meta: wfY7PfkC8fFkMeta || {},
    alias: wfY7PfkC8fFkMeta?.alias || [],
    redirect: wfY7PfkC8fFkMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration/wf.vue").then(m => m.default || m)
  }
],
    meta: powerGenerationpE5wQfTEDQMeta || {},
    alias: powerGenerationpE5wQfTEDQMeta?.alias || [],
    redirect: powerGenerationpE5wQfTEDQMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/powerGeneration.vue").then(m => m.default || m)
  },
  {
    name: reportjDeY8U8pSaMeta?.name ?? "mobile-user-id-views-report",
    path: reportjDeY8U8pSaMeta?.path ?? "report",
    children: [
  {
    name: geot5V8oyafy7Meta?.name ?? "mobile-user-id-views-report-geo",
    path: geot5V8oyafy7Meta?.path ?? "geo",
    meta: geot5V8oyafy7Meta || {},
    alias: geot5V8oyafy7Meta?.alias || [],
    redirect: geot5V8oyafy7Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/report/geo.vue").then(m => m.default || m)
  },
  {
    name: pvzR069JKladMeta?.name ?? "mobile-user-id-views-report-pv",
    path: pvzR069JKladMeta?.path ?? "pv",
    meta: pvzR069JKladMeta || {},
    alias: pvzR069JKladMeta?.alias || [],
    redirect: pvzR069JKladMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/report/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatWAM95BnCXIMeta?.name ?? "mobile-user-id-views-report-solarheat",
    path: solarheatWAM95BnCXIMeta?.path ?? "solarheat",
    meta: solarheatWAM95BnCXIMeta || {},
    alias: solarheatWAM95BnCXIMeta?.alias || [],
    redirect: solarheatWAM95BnCXIMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/report/solarheat.vue").then(m => m.default || m)
  }
],
    meta: reportjDeY8U8pSaMeta || {},
    alias: reportjDeY8U8pSaMeta?.alias || [],
    redirect: reportjDeY8U8pSaMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/report.vue").then(m => m.default || m)
  },
  {
    name: reportPDF3xJzJUiVW8Meta?.name ?? "mobile-user-id-views-reportPDF",
    path: reportPDF3xJzJUiVW8Meta?.path ?? "reportPDF",
    children: [
  {
    name: pvSWt4qbzC2eMeta?.name ?? "mobile-user-id-views-reportPDF-pv",
    path: pvSWt4qbzC2eMeta?.path ?? "pv",
    meta: pvSWt4qbzC2eMeta || {},
    alias: pvSWt4qbzC2eMeta?.alias || [],
    redirect: pvSWt4qbzC2eMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/reportPDF/pv.vue").then(m => m.default || m)
  }
],
    meta: reportPDF3xJzJUiVW8Meta || {},
    alias: reportPDF3xJzJUiVW8Meta?.alias || [],
    redirect: reportPDF3xJzJUiVW8Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/reportPDF.vue").then(m => m.default || m)
  },
  {
    name: statusnlEYHyHU4nMeta?.name ?? "mobile-user-id-views-status",
    path: statusnlEYHyHU4nMeta?.path ?? "status",
    meta: statusnlEYHyHU4nMeta || {},
    alias: statusnlEYHyHU4nMeta?.alias || [],
    redirect: statusnlEYHyHU4nMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views/status.vue").then(m => m.default || m)
  }
],
    meta: viewsjsOIarrqfOMeta || {},
    alias: viewsjsOIarrqfOMeta?.alias || [],
    redirect: viewsjsOIarrqfOMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id]/views.vue").then(m => m.default || m)
  }
],
    name: _91id_93zt2PHYf3ZaMeta?.name ?? undefined,
    meta: _91id_93zt2PHYf3ZaMeta || {},
    alias: _91id_93zt2PHYf3ZaMeta?.alias || [],
    redirect: _91id_93zt2PHYf3ZaMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/[id].vue").then(m => m.default || m)
  },
  {
    name: finduserbZCO25FgXTMeta?.name ?? "mobile-user-finduser",
    path: finduserbZCO25FgXTMeta?.path ?? "finduser",
    children: [
  {
    name: doneFindidDQ2fRBCvvQMeta?.name ?? "mobile-user-finduser-doneFindid",
    path: doneFindidDQ2fRBCvvQMeta?.path ?? "doneFindid",
    meta: doneFindidDQ2fRBCvvQMeta || {},
    alias: doneFindidDQ2fRBCvvQMeta?.alias || [],
    redirect: doneFindidDQ2fRBCvvQMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/finduser/doneFindid.vue").then(m => m.default || m)
  },
  {
    name: doneFindpasskxUsQDQdWlMeta?.name ?? "mobile-user-finduser-doneFindpass",
    path: doneFindpasskxUsQDQdWlMeta?.path ?? "doneFindpass",
    meta: doneFindpasskxUsQDQdWlMeta || {},
    alias: doneFindpasskxUsQDQdWlMeta?.alias || [],
    redirect: doneFindpasskxUsQDQdWlMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/finduser/doneFindpass.vue").then(m => m.default || m)
  },
  {
    name: findidaCu47FWQ5IMeta?.name ?? "mobile-user-finduser-findid",
    path: findidaCu47FWQ5IMeta?.path ?? "findid",
    meta: findidaCu47FWQ5IMeta || {},
    alias: findidaCu47FWQ5IMeta?.alias || [],
    redirect: findidaCu47FWQ5IMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/finduser/findid.vue").then(m => m.default || m)
  },
  {
    name: findpassuNB2Ycdtv0Meta?.name ?? "mobile-user-finduser-findpass",
    path: findpassuNB2Ycdtv0Meta?.path ?? "findpass",
    meta: findpassuNB2Ycdtv0Meta || {},
    alias: findpassuNB2Ycdtv0Meta?.alias || [],
    redirect: findpassuNB2Ycdtv0Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/finduser/findpass.vue").then(m => m.default || m)
  }
],
    meta: finduserbZCO25FgXTMeta || {},
    alias: finduserbZCO25FgXTMeta?.alias || [],
    redirect: finduserbZCO25FgXTMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/finduser.vue").then(m => m.default || m)
  },
  {
    name: intro3X1WIFuggrMeta?.name ?? "mobile-user-intro",
    path: intro3X1WIFuggrMeta?.path ?? "intro",
    meta: intro3X1WIFuggrMeta || {},
    alias: intro3X1WIFuggrMeta?.alias || [],
    redirect: intro3X1WIFuggrMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/intro.vue").then(m => m.default || m)
  },
  {
    name: loginDwbC2bNQ6kMeta?.name ?? "mobile-user-login",
    path: loginDwbC2bNQ6kMeta?.path ?? "login",
    meta: loginDwbC2bNQ6kMeta || {},
    alias: loginDwbC2bNQ6kMeta?.alias || [],
    redirect: loginDwbC2bNQ6kMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/login.vue").then(m => m.default || m)
  },
  {
    name: logoutb49oDmIa3CMeta?.name ?? "mobile-user-logout",
    path: logoutb49oDmIa3CMeta?.path ?? "logout",
    meta: logoutb49oDmIa3CMeta || {},
    alias: logoutb49oDmIa3CMeta?.alias || [],
    redirect: logoutb49oDmIa3CMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/logout.vue").then(m => m.default || m)
  },
  {
    name: signinkvbpdwSdO2Meta?.name ?? "mobile-user-signin",
    path: signinkvbpdwSdO2Meta?.path ?? "signin",
    children: [
  {
    name: personTermsukVGHBei83Meta?.name ?? "mobile-user-signin-personTerms",
    path: personTermsukVGHBei83Meta?.path ?? "personTerms",
    meta: personTermsukVGHBei83Meta || {},
    alias: personTermsukVGHBei83Meta?.alias || [],
    redirect: personTermsukVGHBei83Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/signin/personTerms.vue").then(m => m.default || m)
  },
  {
    name: termff2DNPAjbSMeta?.name ?? "mobile-user-signin-term",
    path: termff2DNPAjbSMeta?.path ?? "term",
    meta: termff2DNPAjbSMeta || {},
    alias: termff2DNPAjbSMeta?.alias || [],
    redirect: termff2DNPAjbSMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/signin/term.vue").then(m => m.default || m)
  },
  {
    name: useTermsqaFyLe8steMeta?.name ?? "mobile-user-signin-useTerms",
    path: useTermsqaFyLe8steMeta?.path ?? "useTerms",
    meta: useTermsqaFyLe8steMeta || {},
    alias: useTermsqaFyLe8steMeta?.alias || [],
    redirect: useTermsqaFyLe8steMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/signin/useTerms.vue").then(m => m.default || m)
  }
],
    meta: signinkvbpdwSdO2Meta || {},
    alias: signinkvbpdwSdO2Meta?.alias || [],
    redirect: signinkvbpdwSdO2Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user/signin.vue").then(m => m.default || m)
  }
],
    meta: useriHbWZk1z41Meta || {},
    alias: useriHbWZk1z41Meta?.alias || [],
    redirect: useriHbWZk1z41Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile/user.vue").then(m => m.default || m)
  }
],
    meta: mobilel41wG6mxVaMeta || {},
    alias: mobilel41wG6mxVaMeta?.alias || [],
    redirect: mobilel41wG6mxVaMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/mobile.vue").then(m => m.default || m)
  },
  {
    name: swagger_45uiqU4jv7mnbHMeta?.name ?? "swagger-ui",
    path: swagger_45uiqU4jv7mnbHMeta?.path ?? "/swagger-ui",
    meta: swagger_45uiqU4jv7mnbHMeta || {},
    alias: swagger_45uiqU4jv7mnbHMeta?.alias || [],
    redirect: swagger_45uiqU4jv7mnbHMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/swagger-ui.vue").then(m => m.default || m)
  },
  {
    name: login2mTmulNEouMeta?.name ?? "test-login",
    path: login2mTmulNEouMeta?.path ?? "/test/login",
    meta: login2mTmulNEouMeta || {},
    alias: login2mTmulNEouMeta?.alias || [],
    redirect: login2mTmulNEouMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/test/login.vue").then(m => m.default || m)
  },
  {
    name: mainMNevBwmk2TMeta?.name ?? "test-main",
    path: mainMNevBwmk2TMeta?.path ?? "/test/main",
    meta: mainMNevBwmk2TMeta || {},
    alias: mainMNevBwmk2TMeta?.alias || [],
    redirect: mainMNevBwmk2TMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/test/main.vue").then(m => m.default || m)
  },
  {
    name: tocwun8sciQGaMeta?.name ?? "toc",
    path: tocwun8sciQGaMeta?.path ?? "/toc",
    children: [
  {
    name: analysisLhngEOq592Meta?.name ?? "toc-analysis",
    path: analysisLhngEOq592Meta?.path ?? "analysis",
    children: [
  {
    name: compare_45ownerQAaGlSAWdoMeta?.name ?? "toc-analysis-compare-owner",
    path: compare_45ownerQAaGlSAWdoMeta?.path ?? "compare-owner",
    meta: compare_45ownerQAaGlSAWdoMeta || {},
    alias: compare_45ownerQAaGlSAWdoMeta?.alias || [],
    redirect: compare_45ownerQAaGlSAWdoMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/analysis/compare-owner.vue").then(m => m.default || m)
  },
  {
    name: compare_45regionr1tOdvQWI5Meta?.name ?? "toc-analysis-compare-region",
    path: compare_45regionr1tOdvQWI5Meta?.path ?? "compare-region",
    meta: compare_45regionr1tOdvQWI5Meta || {},
    alias: compare_45regionr1tOdvQWI5Meta?.alias || [],
    redirect: compare_45regionr1tOdvQWI5Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/analysis/compare-region.vue").then(m => m.default || m)
  },
  {
    name: geowU5P0hw3NsMeta?.name ?? "toc-analysis-component-geo",
    path: geowU5P0hw3NsMeta?.path ?? "component/geo",
    meta: geowU5P0hw3NsMeta || {},
    alias: geowU5P0hw3NsMeta?.alias || [],
    redirect: geowU5P0hw3NsMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/analysis/component/geo.vue").then(m => m.default || m)
  },
  {
    name: ownerSearchModalxD3f5pF6vCMeta?.name ?? "toc-analysis-component-ownerSearchModal",
    path: ownerSearchModalxD3f5pF6vCMeta?.path ?? "component/ownerSearchModal",
    meta: ownerSearchModalxD3f5pF6vCMeta || {},
    alias: ownerSearchModalxD3f5pF6vCMeta?.alias || [],
    redirect: ownerSearchModalxD3f5pF6vCMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/analysis/component/ownerSearchModal.vue").then(m => m.default || m)
  },
  {
    name: pvGjXyzWvV1jMeta?.name ?? "toc-analysis-component-pv",
    path: pvGjXyzWvV1jMeta?.path ?? "component/pv",
    meta: pvGjXyzWvV1jMeta || {},
    alias: pvGjXyzWvV1jMeta?.alias || [],
    redirect: pvGjXyzWvV1jMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/analysis/component/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatR8FiQrGmC3Meta?.name ?? "toc-analysis-component-solarheat",
    path: solarheatR8FiQrGmC3Meta?.path ?? "component/solarheat",
    meta: solarheatR8FiQrGmC3Meta || {},
    alias: solarheatR8FiQrGmC3Meta?.alias || [],
    redirect: solarheatR8FiQrGmC3Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/analysis/component/solarheat.vue").then(m => m.default || m)
  },
  {
    name: ownerXuuCW5GeM4Meta?.name ?? "toc-analysis-owner",
    path: ownerXuuCW5GeM4Meta?.path ?? "owner",
    meta: ownerXuuCW5GeM4Meta || {},
    alias: ownerXuuCW5GeM4Meta?.alias || [],
    redirect: ownerXuuCW5GeM4Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/analysis/owner.vue").then(m => m.default || m)
  }
],
    meta: analysisLhngEOq592Meta || {},
    alias: analysisLhngEOq592Meta?.alias || [],
    redirect: analysisLhngEOq592Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/analysis.vue").then(m => m.default || m)
  },
  {
    name: Energy_45ListrG7jvuHLaKMeta?.name ?? "toc-component-Dashboard-Energy-List",
    path: Energy_45ListrG7jvuHLaKMeta?.path ?? "component/Dashboard/Energy-List",
    meta: Energy_45ListrG7jvuHLaKMeta || {},
    alias: Energy_45ListrG7jvuHLaKMeta?.alias || [],
    redirect: Energy_45ListrG7jvuHLaKMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/Dashboard/Energy-List.vue").then(m => m.default || m)
  },
  {
    name: card_45base7LvAlatVyLMeta?.name ?? "toc-component-Dashboard-card-base",
    path: card_45base7LvAlatVyLMeta?.path ?? "component/Dashboard/card-base",
    meta: card_45base7LvAlatVyLMeta || {},
    alias: card_45base7LvAlatVyLMeta?.alias || [],
    redirect: card_45base7LvAlatVyLMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/Dashboard/card-base.vue").then(m => m.default || m)
  },
  {
    name: energy_45cardmN11umJ0pjMeta?.name ?? "toc-component-Dashboard-energy-card",
    path: energy_45cardmN11umJ0pjMeta?.path ?? "component/Dashboard/energy-card",
    meta: energy_45cardmN11umJ0pjMeta || {},
    alias: energy_45cardmN11umJ0pjMeta?.alias || [],
    redirect: energy_45cardmN11umJ0pjMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/Dashboard/energy-card.vue").then(m => m.default || m)
  },
  {
    name: energy_45card2Tl480SuqaeMeta?.name ?? "toc-component-Dashboard-energy-card2",
    path: energy_45card2Tl480SuqaeMeta?.path ?? "component/Dashboard/energy-card2",
    meta: energy_45card2Tl480SuqaeMeta || {},
    alias: energy_45card2Tl480SuqaeMeta?.alias || [],
    redirect: energy_45card2Tl480SuqaeMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/Dashboard/energy-card2.vue").then(m => m.default || m)
  },
  {
    name: info_45card89vfZUAWFIMeta?.name ?? "toc-component-Dashboard-info-card",
    path: info_45card89vfZUAWFIMeta?.path ?? "component/Dashboard/info-card",
    meta: info_45card89vfZUAWFIMeta || {},
    alias: info_45card89vfZUAWFIMeta?.alias || [],
    redirect: info_45card89vfZUAWFIMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/Dashboard/info-card.vue").then(m => m.default || m)
  },
  {
    name: link_45rate_45cardCYaFqq6HM3Meta?.name ?? "toc-component-Dashboard-link-rate-card",
    path: link_45rate_45cardCYaFqq6HM3Meta?.path ?? "component/Dashboard/link-rate-card",
    meta: link_45rate_45cardCYaFqq6HM3Meta || {},
    alias: link_45rate_45cardCYaFqq6HM3Meta?.alias || [],
    redirect: link_45rate_45cardCYaFqq6HM3Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/Dashboard/link-rate-card.vue").then(m => m.default || m)
  },
  {
    name: FileUpload7vxK6neCnDMeta?.name ?? "toc-component-FileUpload",
    path: FileUpload7vxK6neCnDMeta?.path ?? "component/FileUpload",
    meta: FileUpload7vxK6neCnDMeta || {},
    alias: FileUpload7vxK6neCnDMeta?.alias || [],
    redirect: FileUpload7vxK6neCnDMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/FileUpload.vue").then(m => m.default || m)
  },
  {
    name: index2fj6gi8FgfMeta?.name ?? "toc-component-InstallInfo",
    path: index2fj6gi8FgfMeta?.path ?? "component/InstallInfo",
    meta: index2fj6gi8FgfMeta || {},
    alias: index2fj6gi8FgfMeta?.alias || [],
    redirect: index2fj6gi8FgfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/InstallInfo/index.vue").then(m => m.default || m)
  },
  {
    name: pvaSGDJDlWE4Meta?.name ?? "toc-component-InstallInfo-pv",
    path: pvaSGDJDlWE4Meta?.path ?? "component/InstallInfo/pv",
    meta: pvaSGDJDlWE4Meta || {},
    alias: pvaSGDJDlWE4Meta?.alias || [],
    redirect: pvaSGDJDlWE4Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/InstallInfo/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheat0agNLKNnBZMeta?.name ?? "toc-component-InstallInfo-solarheat",
    path: solarheat0agNLKNnBZMeta?.path ?? "component/InstallInfo/solarheat",
    meta: solarheat0agNLKNnBZMeta || {},
    alias: solarheat0agNLKNnBZMeta?.alias || [],
    redirect: solarheat0agNLKNnBZMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/InstallInfo/solarheat.vue").then(m => m.default || m)
  },
  {
    name: pvspAthVFSgMMeta?.name ?? "toc-component-InstruInfo-pv",
    path: pvspAthVFSgMMeta?.path ?? "component/InstruInfo/pv",
    meta: pvspAthVFSgMMeta || {},
    alias: pvspAthVFSgMMeta?.alias || [],
    redirect: pvspAthVFSgMMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/InstruInfo/pv.vue").then(m => m.default || m)
  },
  {
    name: indexkmIioBd425Meta?.name ?? "toc-component-ManagerInfo",
    path: indexkmIioBd425Meta?.path ?? "component/ManagerInfo",
    meta: indexkmIioBd425Meta || {},
    alias: indexkmIioBd425Meta?.alias || [],
    redirect: indexkmIioBd425Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/ManagerInfo/index.vue").then(m => m.default || m)
  },
  {
    name: pvKev74EBbcFMeta?.name ?? "toc-component-ManagerInfo-pv",
    path: pvKev74EBbcFMeta?.path ?? "component/ManagerInfo/pv",
    meta: pvKev74EBbcFMeta || {},
    alias: pvKev74EBbcFMeta?.alias || [],
    redirect: pvKev74EBbcFMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/ManagerInfo/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheat1LwCCRIJ3rMeta?.name ?? "toc-component-ManagerInfo-solarheat",
    path: solarheat1LwCCRIJ3rMeta?.path ?? "component/ManagerInfo/solarheat",
    meta: solarheat1LwCCRIJ3rMeta || {},
    alias: solarheat1LwCCRIJ3rMeta?.alias || [],
    redirect: solarheat1LwCCRIJ3rMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/ManagerInfo/solarheat.vue").then(m => m.default || m)
  },
  {
    name: pvvtp58yu1nhMeta?.name ?? "toc-component-OwnerInfo-ManagerInfo-pv",
    path: pvvtp58yu1nhMeta?.path ?? "component/OwnerInfo/ManagerInfo/pv",
    meta: pvvtp58yu1nhMeta || {},
    alias: pvvtp58yu1nhMeta?.alias || [],
    redirect: pvvtp58yu1nhMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/ManagerInfo/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatHyLoXHbH66Meta?.name ?? "toc-component-OwnerInfo-ManagerInfo-solarheat",
    path: solarheatHyLoXHbH66Meta?.path ?? "component/OwnerInfo/ManagerInfo/solarheat",
    meta: solarheatHyLoXHbH66Meta || {},
    alias: solarheatHyLoXHbH66Meta?.alias || [],
    redirect: solarheatHyLoXHbH66Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/ManagerInfo/solarheat.vue").then(m => m.default || m)
  },
  {
    name: indexM8aV5Y6abIMeta?.name ?? "toc-component-OwnerInfo",
    path: indexM8aV5Y6abIMeta?.path ?? "component/OwnerInfo",
    meta: indexM8aV5Y6abIMeta || {},
    alias: indexM8aV5Y6abIMeta?.alias || [],
    redirect: indexM8aV5Y6abIMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/index.vue").then(m => m.default || m)
  },
  {
    name: pv6ihKZJgf4PMeta?.name ?? "toc-component-OwnerInfo-pv",
    path: pv6ihKZJgf4PMeta?.path ?? "component/OwnerInfo/pv",
    meta: pv6ihKZJgf4PMeta || {},
    alias: pv6ihKZJgf4PMeta?.alias || [],
    redirect: pv6ihKZJgf4PMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/pv.vue").then(m => m.default || m)
  },
  {
    name: solarqTEmUesK3qMeta?.name ?? "toc-component-OwnerInfo-solar",
    path: solarqTEmUesK3qMeta?.path ?? "component/OwnerInfo/solar",
    meta: solarqTEmUesK3qMeta || {},
    alias: solarqTEmUesK3qMeta?.alias || [],
    redirect: solarqTEmUesK3qMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/OwnerInfo/solar.vue").then(m => m.default || m)
  },
  {
    name: index2RKdZk6KYdMeta?.name ?? "toc-component-RtuInfo",
    path: index2RKdZk6KYdMeta?.path ?? "component/RtuInfo",
    meta: index2RKdZk6KYdMeta || {},
    alias: index2RKdZk6KYdMeta?.alias || [],
    redirect: index2RKdZk6KYdMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/RtuInfo/index.vue").then(m => m.default || m)
  },
  {
    name: common_45code_45dropdownLliBTgEOVgMeta?.name ?? "toc-component-SearchBox-common-code-dropdown",
    path: common_45code_45dropdownLliBTgEOVgMeta?.path ?? "component/SearchBox/common-code-dropdown",
    meta: common_45code_45dropdownLliBTgEOVgMeta || {},
    alias: common_45code_45dropdownLliBTgEOVgMeta?.alias || [],
    redirect: common_45code_45dropdownLliBTgEOVgMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/SearchBox/common-code-dropdown.vue").then(m => m.default || m)
  },
  {
    name: date_45set_45reportwAMfAgSDnpMeta?.name ?? "toc-component-SearchBox-date-set-report",
    path: date_45set_45reportwAMfAgSDnpMeta?.path ?? "component/SearchBox/date-set-report",
    meta: date_45set_45reportwAMfAgSDnpMeta || {},
    alias: date_45set_45reportwAMfAgSDnpMeta?.alias || [],
    redirect: date_45set_45reportwAMfAgSDnpMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/SearchBox/date-set-report.vue").then(m => m.default || m)
  },
  {
    name: date_45setOvdaxjHWnlMeta?.name ?? "toc-component-SearchBox-date-set",
    path: date_45setOvdaxjHWnlMeta?.path ?? "component/SearchBox/date-set",
    meta: date_45setOvdaxjHWnlMeta || {},
    alias: date_45setOvdaxjHWnlMeta?.alias || [],
    redirect: date_45setOvdaxjHWnlMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/SearchBox/date-set.vue").then(m => m.default || m)
  },
  {
    name: region_45sethRGcN7YnFIMeta?.name ?? "toc-component-SearchBox-region-set",
    path: region_45sethRGcN7YnFIMeta?.path ?? "component/SearchBox/region-set",
    meta: region_45sethRGcN7YnFIMeta || {},
    alias: region_45sethRGcN7YnFIMeta?.alias || [],
    redirect: region_45sethRGcN7YnFIMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/SearchBox/region-set.vue").then(m => m.default || m)
  },
  {
    name: geo8RsEQPVCjDMeta?.name ?? "toc-component-SpecInfo-geo",
    path: geo8RsEQPVCjDMeta?.path ?? "component/SpecInfo/geo",
    meta: geo8RsEQPVCjDMeta || {},
    alias: geo8RsEQPVCjDMeta?.alias || [],
    redirect: geo8RsEQPVCjDMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/SpecInfo/geo.vue").then(m => m.default || m)
  },
  {
    name: pvc6gZsGac0QMeta?.name ?? "toc-component-SpecInfo-pv",
    path: pvc6gZsGac0QMeta?.path ?? "component/SpecInfo/pv",
    meta: pvc6gZsGac0QMeta || {},
    alias: pvc6gZsGac0QMeta?.alias || [],
    redirect: pvc6gZsGac0QMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/SpecInfo/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatIPTWbrFN0mMeta?.name ?? "toc-component-SpecInfo-solarheat",
    path: solarheatIPTWbrFN0mMeta?.path ?? "component/SpecInfo/solarheat",
    meta: solarheatIPTWbrFN0mMeta || {},
    alias: solarheatIPTWbrFN0mMeta?.alias || [],
    redirect: solarheatIPTWbrFN0mMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/SpecInfo/solarheat.vue").then(m => m.default || m)
  },
  {
    name: Table_45BoxZbCGGSfDwOMeta?.name ?? "toc-component-Table-Box",
    path: Table_45BoxZbCGGSfDwOMeta?.path ?? "component/Table-Box",
    meta: Table_45BoxZbCGGSfDwOMeta || {},
    alias: Table_45BoxZbCGGSfDwOMeta?.alias || [],
    redirect: Table_45BoxZbCGGSfDwOMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/Table-Box.vue").then(m => m.default || m)
  },
  {
    name: api_45search_45boxfWui6l2fZfMeta?.name ?? "toc-component-api-search-box",
    path: api_45search_45boxfWui6l2fZfMeta?.path ?? "component/api-search-box",
    meta: api_45search_45boxfWui6l2fZfMeta || {},
    alias: api_45search_45boxfWui6l2fZfMeta?.alias || [],
    redirect: api_45search_45boxfWui6l2fZfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/api-search-box.vue").then(m => m.default || m)
  },
  {
    name: data_45tabledmvFjXegcGMeta?.name ?? "toc-component-data-table",
    path: data_45tabledmvFjXegcGMeta?.path ?? "component/data-table",
    meta: data_45tabledmvFjXegcGMeta || {},
    alias: data_45tabledmvFjXegcGMeta?.alias || [],
    redirect: data_45tabledmvFjXegcGMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/data-table.vue").then(m => m.default || m)
  },
  {
    name: event_45search_45gridNQ8N4Yz6NfMeta?.name ?? "toc-component-event-search-grid",
    path: event_45search_45gridNQ8N4Yz6NfMeta?.path ?? "component/event-search-grid",
    meta: event_45search_45gridNQ8N4Yz6NfMeta || {},
    alias: event_45search_45gridNQ8N4Yz6NfMeta?.alias || [],
    redirect: event_45search_45gridNQ8N4Yz6NfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/event-search-grid.vue").then(m => m.default || m)
  },
  {
    name: info_45boxouaJ7s4uMkMeta?.name ?? "toc-component-info-box",
    path: info_45boxouaJ7s4uMkMeta?.path ?? "component/info-box",
    meta: info_45boxouaJ7s4uMkMeta || {},
    alias: info_45boxouaJ7s4uMkMeta?.alias || [],
    redirect: info_45boxouaJ7s4uMkMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/info-box.vue").then(m => m.default || m)
  },
  {
    name: region_45selectYeKmKNwtPWMeta?.name ?? "toc-component-region-select",
    path: region_45selectYeKmKNwtPWMeta?.path ?? "component/region-select",
    meta: region_45selectYeKmKNwtPWMeta || {},
    alias: region_45selectYeKmKNwtPWMeta?.alias || [],
    redirect: region_45selectYeKmKNwtPWMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/region-select.vue").then(m => m.default || m)
  },
  {
    name: search_45boxF1NuhGzNU2Meta?.name ?? "toc-component-search-box",
    path: search_45boxF1NuhGzNU2Meta?.path ?? "component/search-box",
    meta: search_45boxF1NuhGzNU2Meta || {},
    alias: search_45boxF1NuhGzNU2Meta?.alias || [],
    redirect: search_45boxF1NuhGzNU2Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/search-box.vue").then(m => m.default || m)
  },
  {
    name: table_45pagingEZnpWglr5FMeta?.name ?? "toc-component-table-paging",
    path: table_45pagingEZnpWglr5FMeta?.path ?? "component/table-paging",
    meta: table_45pagingEZnpWglr5FMeta || {},
    alias: table_45pagingEZnpWglr5FMeta?.alias || [],
    redirect: table_45pagingEZnpWglr5FMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/table-paging.vue").then(m => m.default || m)
  },
  {
    name: toc_45templateR7oHdTxjPhMeta?.name ?? "toc-component-template-toc-template",
    path: toc_45templateR7oHdTxjPhMeta?.path ?? "component/template/toc-template",
    meta: toc_45templateR7oHdTxjPhMeta || {},
    alias: toc_45templateR7oHdTxjPhMeta?.alias || [],
    redirect: toc_45templateR7oHdTxjPhMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/template/toc-template.vue").then(m => m.default || m)
  },
  {
    name: test_45gridHoBG7F9qZvMeta?.name ?? "toc-component-test-grid",
    path: test_45gridHoBG7F9qZvMeta?.path ?? "component/test-grid",
    meta: test_45gridHoBG7F9qZvMeta || {},
    alias: test_45gridHoBG7F9qZvMeta?.alias || [],
    redirect: test_45gridHoBG7F9qZvMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/component/test-grid.vue").then(m => m.default || m)
  },
  {
    name: customer0wHDoLfcv4Meta?.name ?? "toc-customer",
    path: customer0wHDoLfcv4Meta?.path ?? "customer",
    children: [
  {
    name: _91id_93vyP2CvtFEYMeta?.name ?? "toc-customer-faq-id",
    path: _91id_93vyP2CvtFEYMeta?.path ?? "faq/:id",
    meta: _91id_93vyP2CvtFEYMeta || {},
    alias: _91id_93vyP2CvtFEYMeta?.alias || [],
    redirect: _91id_93vyP2CvtFEYMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/faq/[id].vue").then(m => m.default || m)
  },
  {
    name: indexdXwKHOhMnzMeta?.name ?? "toc-customer-faq",
    path: indexdXwKHOhMnzMeta?.path ?? "faq",
    meta: indexdXwKHOhMnzMeta || {},
    alias: indexdXwKHOhMnzMeta?.alias || [],
    redirect: indexdXwKHOhMnzMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/faq/index.vue").then(m => m.default || m)
  },
  {
    name: faq_companyCR3fq4er2HMeta?.name ?? "toc-customer-faq_company",
    path: faq_companyCR3fq4er2HMeta?.path ?? "faq_company",
    meta: faq_companyCR3fq4er2HMeta || {},
    alias: faq_companyCR3fq4er2HMeta?.alias || [],
    redirect: faq_companyCR3fq4er2HMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/faq_company.vue").then(m => m.default || m)
  },
  {
    name: login_45termsOHiSZJtX4WMeta?.name ?? "toc-customer-login-terms",
    path: login_45termsOHiSZJtX4WMeta?.path ?? "login-terms",
    meta: login_45termsOHiSZJtX4WMeta || {},
    alias: login_45termsOHiSZJtX4WMeta?.alias || [],
    redirect: login_45termsOHiSZJtX4WMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/login-terms.vue").then(m => m.default || m)
  },
  {
    path: noticewVG9ZFG85gMeta?.path ?? "notice",
    children: [
  {
    name: _91id_93lGovtSJg8LMeta?.name ?? "toc-customer-notice-id",
    path: _91id_93lGovtSJg8LMeta?.path ?? ":id",
    meta: _91id_93lGovtSJg8LMeta || {},
    alias: _91id_93lGovtSJg8LMeta?.alias || [],
    redirect: _91id_93lGovtSJg8LMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: indexNEjvbBKgR4Meta?.name ?? "toc-customer-notice",
    path: indexNEjvbBKgR4Meta?.path ?? "",
    meta: indexNEjvbBKgR4Meta || {},
    alias: indexNEjvbBKgR4Meta?.alias || [],
    redirect: indexNEjvbBKgR4Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/notice/index.vue").then(m => m.default || m)
  }
],
    name: noticewVG9ZFG85gMeta?.name ?? undefined,
    meta: noticewVG9ZFG85gMeta || {},
    alias: noticewVG9ZFG85gMeta?.alias || [],
    redirect: noticewVG9ZFG85gMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/notice.vue").then(m => m.default || m)
  },
  {
    path: qnacfD0xAvqV4Meta?.path ?? "qna",
    children: [
  {
    name: _91id_937MmJQ2VgDUMeta?.name ?? "toc-customer-qna-id",
    path: _91id_937MmJQ2VgDUMeta?.path ?? ":id",
    meta: _91id_937MmJQ2VgDUMeta || {},
    alias: _91id_937MmJQ2VgDUMeta?.alias || [],
    redirect: _91id_937MmJQ2VgDUMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/qna/[id].vue").then(m => m.default || m)
  },
  {
    name: add5qs0JkSv0AMeta?.name ?? "toc-customer-qna-add",
    path: add5qs0JkSv0AMeta?.path ?? "add",
    meta: add5qs0JkSv0AMeta || {},
    alias: add5qs0JkSv0AMeta?.alias || [],
    redirect: add5qs0JkSv0AMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/qna/add.vue").then(m => m.default || m)
  },
  {
    name: indexTvbZfBzJwyMeta?.name ?? "toc-customer-qna",
    path: indexTvbZfBzJwyMeta?.path ?? "",
    meta: indexTvbZfBzJwyMeta || {},
    alias: indexTvbZfBzJwyMeta?.alias || [],
    redirect: indexTvbZfBzJwyMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/qna/index.vue").then(m => m.default || m)
  }
],
    name: qnacfD0xAvqV4Meta?.name ?? undefined,
    meta: qnacfD0xAvqV4Meta || {},
    alias: qnacfD0xAvqV4Meta?.alias || [],
    redirect: qnacfD0xAvqV4Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/qna.vue").then(m => m.default || m)
  },
  {
    path: referenceol19MYR9qoMeta?.path ?? "reference",
    children: [
  {
    name: _91id_93Ep94w1MWrDMeta?.name ?? "toc-customer-reference-id",
    path: _91id_93Ep94w1MWrDMeta?.path ?? ":id",
    meta: _91id_93Ep94w1MWrDMeta || {},
    alias: _91id_93Ep94w1MWrDMeta?.alias || [],
    redirect: _91id_93Ep94w1MWrDMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/reference/[id].vue").then(m => m.default || m)
  },
  {
    name: indexJ2ymjz9EG7Meta?.name ?? "toc-customer-reference",
    path: indexJ2ymjz9EG7Meta?.path ?? "",
    meta: indexJ2ymjz9EG7Meta || {},
    alias: indexJ2ymjz9EG7Meta?.alias || [],
    redirect: indexJ2ymjz9EG7Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/reference/index.vue").then(m => m.default || m)
  }
],
    name: referenceol19MYR9qoMeta?.name ?? undefined,
    meta: referenceol19MYR9qoMeta || {},
    alias: referenceol19MYR9qoMeta?.alias || [],
    redirect: referenceol19MYR9qoMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/reference.vue").then(m => m.default || m)
  },
  {
    name: terms1vQMjYNDkUVMeta?.name ?? "toc-customer-terms-list-terms1",
    path: terms1vQMjYNDkUVMeta?.path ?? "terms-list/terms1",
    meta: terms1vQMjYNDkUVMeta || {},
    alias: terms1vQMjYNDkUVMeta?.alias || [],
    redirect: terms1vQMjYNDkUVMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/terms-list/terms1.vue").then(m => m.default || m)
  },
  {
    name: terms23W00aClzfpMeta?.name ?? "toc-customer-terms-list-terms2",
    path: terms23W00aClzfpMeta?.path ?? "terms-list/terms2",
    meta: terms23W00aClzfpMeta || {},
    alias: terms23W00aClzfpMeta?.alias || [],
    redirect: terms23W00aClzfpMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/terms-list/terms2.vue").then(m => m.default || m)
  },
  {
    name: termsJnplQ2it8AMeta?.name ?? "toc-customer-terms",
    path: termsJnplQ2it8AMeta?.path ?? "terms",
    meta: termsJnplQ2it8AMeta || {},
    alias: termsJnplQ2it8AMeta?.alias || [],
    redirect: termsJnplQ2it8AMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer/terms.vue").then(m => m.default || m)
  }
],
    meta: customer0wHDoLfcv4Meta || {},
    alias: customer0wHDoLfcv4Meta?.alias || [],
    redirect: customer0wHDoLfcv4Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/customer.vue").then(m => m.default || m)
  },
  {
    name: equipwNdz4pFSznMeta?.name ?? "toc-equip",
    path: equipwNdz4pFSznMeta?.path ?? "equip",
    children: [
  {
    name: equip_45detail6m5rPDWIbzMeta?.name ?? "toc-equip-pathinfo-equip-detail",
    path: equip_45detail6m5rPDWIbzMeta?.path ?? ":pathinfo/equip-detail",
    children: [
  {
    name: _91cid_93WhsLZAmFP4Meta?.name ?? "toc-equip-pathinfo-equip-detail-cid",
    path: _91cid_93WhsLZAmFP4Meta?.path ?? ":cid",
    children: [
  {
    name: geo_45prod_45card8yz7ylAqn7Meta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-geo-prod-card",
    path: geo_45prod_45card8yz7ylAqn7Meta?.path ?? "component/geo-prod-card",
    meta: geo_45prod_45card8yz7ylAqn7Meta || {},
    alias: geo_45prod_45card8yz7ylAqn7Meta?.alias || [],
    redirect: geo_45prod_45card8yz7ylAqn7Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/geo-prod-card.vue").then(m => m.default || m)
  },
  {
    name: geo_45prod_45chartejNm1S1QSnMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-geo-prod-chart",
    path: geo_45prod_45chartejNm1S1QSnMeta?.path ?? "component/geo-prod-chart",
    meta: geo_45prod_45chartejNm1S1QSnMeta || {},
    alias: geo_45prod_45chartejNm1S1QSnMeta?.alias || [],
    redirect: geo_45prod_45chartejNm1S1QSnMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/geo-prod-chart.vue").then(m => m.default || m)
  },
  {
    name: geo_45usage_45cardHte67zNvMbMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-geo-usage-card",
    path: geo_45usage_45cardHte67zNvMbMeta?.path ?? "component/geo-usage-card",
    meta: geo_45usage_45cardHte67zNvMbMeta || {},
    alias: geo_45usage_45cardHte67zNvMbMeta?.alias || [],
    redirect: geo_45usage_45cardHte67zNvMbMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/geo-usage-card.vue").then(m => m.default || m)
  },
  {
    name: geo_45usage_45chartA5xXYkkxXCMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-geo-usage-chart",
    path: geo_45usage_45chartA5xXYkkxXCMeta?.path ?? "component/geo-usage-chart",
    meta: geo_45usage_45chartA5xXYkkxXCMeta || {},
    alias: geo_45usage_45chartA5xXYkkxXCMeta?.alias || [],
    redirect: geo_45usage_45chartA5xXYkkxXCMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/geo-usage-chart.vue").then(m => m.default || m)
  },
  {
    name: raw_45data_45chartsfQmlbdGstMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-raw-data-chart",
    path: raw_45data_45chartsfQmlbdGstMeta?.path ?? "component/raw-data-chart",
    meta: raw_45data_45chartsfQmlbdGstMeta || {},
    alias: raw_45data_45chartsfQmlbdGstMeta?.alias || [],
    redirect: raw_45data_45chartsfQmlbdGstMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/raw-data-chart.vue").then(m => m.default || m)
  },
  {
    name: raw_45data_45info5L1AfmOIGrMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-raw-data-info",
    path: raw_45data_45info5L1AfmOIGrMeta?.path ?? "component/raw-data-info",
    meta: raw_45data_45info5L1AfmOIGrMeta || {},
    alias: raw_45data_45info5L1AfmOIGrMeta?.alias || [],
    redirect: raw_45data_45info5L1AfmOIGrMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/raw-data-info.vue").then(m => m.default || m)
  },
  {
    name: solarheat_45prod_45cardzJtOJiVRUeMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-solarheat-prod-card",
    path: solarheat_45prod_45cardzJtOJiVRUeMeta?.path ?? "component/solarheat-prod-card",
    meta: solarheat_45prod_45cardzJtOJiVRUeMeta || {},
    alias: solarheat_45prod_45cardzJtOJiVRUeMeta?.alias || [],
    redirect: solarheat_45prod_45cardzJtOJiVRUeMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/solarheat-prod-card.vue").then(m => m.default || m)
  },
  {
    name: solarheat_45prod_45chartHZe7ORPNUhMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-solarheat-prod-chart",
    path: solarheat_45prod_45chartHZe7ORPNUhMeta?.path ?? "component/solarheat-prod-chart",
    meta: solarheat_45prod_45chartHZe7ORPNUhMeta || {},
    alias: solarheat_45prod_45chartHZe7ORPNUhMeta?.alias || [],
    redirect: solarheat_45prod_45chartHZe7ORPNUhMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/solarheat-prod-chart.vue").then(m => m.default || m)
  },
  {
    name: solarheat_45usage_45card5i7yHJ1WxXMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-solarheat-usage-card",
    path: solarheat_45usage_45card5i7yHJ1WxXMeta?.path ?? "component/solarheat-usage-card",
    meta: solarheat_45usage_45card5i7yHJ1WxXMeta || {},
    alias: solarheat_45usage_45card5i7yHJ1WxXMeta?.alias || [],
    redirect: solarheat_45usage_45card5i7yHJ1WxXMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/solarheat-usage-card.vue").then(m => m.default || m)
  },
  {
    name: solarheat_45usage_45chartvv62gSjsX5Meta?.name ?? "toc-equip-pathinfo-equip-detail-cid-component-solarheat-usage-chart",
    path: solarheat_45usage_45chartvv62gSjsX5Meta?.path ?? "component/solarheat-usage-chart",
    meta: solarheat_45usage_45chartvv62gSjsX5Meta || {},
    alias: solarheat_45usage_45chartvv62gSjsX5Meta?.alias || [],
    redirect: solarheat_45usage_45chartvv62gSjsX5Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/component/solarheat-usage-chart.vue").then(m => m.default || m)
  },
  {
    name: geo_45prod90zOGjljcKMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-geo-prod",
    path: geo_45prod90zOGjljcKMeta?.path ?? "geo-prod",
    meta: geo_45prod90zOGjljcKMeta || {},
    alias: geo_45prod90zOGjljcKMeta?.alias || [],
    redirect: geo_45prod90zOGjljcKMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/geo-prod.vue").then(m => m.default || m)
  },
  {
    name: geo_45usageuGqxsCVYF7Meta?.name ?? "toc-equip-pathinfo-equip-detail-cid-geo-usage",
    path: geo_45usageuGqxsCVYF7Meta?.path ?? "geo-usage",
    meta: geo_45usageuGqxsCVYF7Meta || {},
    alias: geo_45usageuGqxsCVYF7Meta?.alias || [],
    redirect: geo_45usageuGqxsCVYF7Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/geo-usage.vue").then(m => m.default || m)
  },
  {
    name: geoXjr9VXC8exMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-geo",
    path: geoXjr9VXC8exMeta?.path ?? "geo",
    meta: geoXjr9VXC8exMeta || {},
    alias: geoXjr9VXC8exMeta?.alias || [],
    redirect: geoXjr9VXC8exMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/geo.vue").then(m => m.default || m)
  },
  {
    name: pvsWtSpgEdLOMeta?.name ?? "toc-equip-pathinfo-equip-detail-cid-pv",
    path: pvsWtSpgEdLOMeta?.path ?? "pv",
    meta: pvsWtSpgEdLOMeta || {},
    alias: pvsWtSpgEdLOMeta?.alias || [],
    redirect: pvsWtSpgEdLOMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheat53UqMSrwG2Meta?.name ?? "toc-equip-pathinfo-equip-detail-cid-solarheat",
    path: solarheat53UqMSrwG2Meta?.path ?? "solarheat",
    meta: solarheat53UqMSrwG2Meta || {},
    alias: solarheat53UqMSrwG2Meta?.alias || [],
    redirect: solarheat53UqMSrwG2Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid]/solarheat.vue").then(m => m.default || m)
  }
],
    meta: _91cid_93WhsLZAmFP4Meta || {},
    alias: _91cid_93WhsLZAmFP4Meta?.alias || [],
    redirect: _91cid_93WhsLZAmFP4Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail/[cid].vue").then(m => m.default || m)
  }
],
    meta: equip_45detail6m5rPDWIbzMeta || {},
    alias: equip_45detail6m5rPDWIbzMeta?.alias || [],
    redirect: equip_45detail6m5rPDWIbzMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-detail.vue").then(m => m.default || m)
  },
  {
    name: equip_45listqhuLKrB1fxMeta?.name ?? "toc-equip-pathinfo-equip-list",
    path: equip_45listqhuLKrB1fxMeta?.path ?? ":pathinfo/equip-list",
    meta: equip_45listqhuLKrB1fxMeta || {},
    alias: equip_45listqhuLKrB1fxMeta?.alias || [],
    redirect: equip_45listqhuLKrB1fxMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-list.vue").then(m => m.default || m)
  },
  {
    name: equip_45mapoSaJa1j5XpMeta?.name ?? "toc-equip-pathinfo-equip-map",
    path: equip_45mapoSaJa1j5XpMeta?.path ?? ":pathinfo/equip-map",
    meta: equip_45mapoSaJa1j5XpMeta || {},
    alias: equip_45mapoSaJa1j5XpMeta?.alias || [],
    redirect: equip_45mapoSaJa1j5XpMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/equip-map.vue").then(m => m.default || m)
  },
  {
    name: measureNsXPe9DZUKMeta?.name ?? "toc-equip-pathinfo-measure",
    path: measureNsXPe9DZUKMeta?.path ?? ":pathinfo/measure",
    meta: measureNsXPe9DZUKMeta || {},
    alias: measureNsXPe9DZUKMeta?.alias || [],
    redirect: measureNsXPe9DZUKMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/measure.vue").then(m => m.default || m)
  },
  {
    name: monitoring_45mapSLlqdGTL1zMeta?.name ?? "toc-equip-pathinfo-monitoring-map",
    path: monitoring_45mapSLlqdGTL1zMeta?.path ?? ":pathinfo/monitoring-map",
    meta: monitoring_45mapSLlqdGTL1zMeta || {},
    alias: monitoring_45mapSLlqdGTL1zMeta?.alias || [],
    redirect: monitoring_45mapSLlqdGTL1zMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/monitoring-map.vue").then(m => m.default || m)
  },
  {
    path: power_45generatePwH6TMFduYMeta?.path ?? ":pathinfo/power-generate",
    children: [
  {
    name: indexwbQByYLJdTMeta?.name ?? "toc-equip-pathinfo-power-generate",
    path: indexwbQByYLJdTMeta?.path ?? "",
    meta: indexwbQByYLJdTMeta || {},
    alias: indexwbQByYLJdTMeta?.alias || [],
    redirect: indexwbQByYLJdTMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/power-generate/index.vue").then(m => m.default || m)
  }
],
    name: power_45generatePwH6TMFduYMeta?.name ?? undefined,
    meta: power_45generatePwH6TMFduYMeta || {},
    alias: power_45generatePwH6TMFduYMeta?.alias || [],
    redirect: power_45generatePwH6TMFduYMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/power-generate.vue").then(m => m.default || m)
  },
  {
    name: rtuV5p40wc4xkMeta?.name ?? "toc-equip-pathinfo-rtu",
    path: rtuV5p40wc4xkMeta?.path ?? ":pathinfo/rtu",
    meta: rtuV5p40wc4xkMeta || {},
    alias: rtuV5p40wc4xkMeta?.alias || [],
    redirect: rtuV5p40wc4xkMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/rtu.vue").then(m => m.default || m)
  },
  {
    path: sitedx4SmCscPlMeta?.path ?? ":pathinfo/site",
    children: [
  {
    name: geoGW7u1KG1d5Meta?.name ?? "toc-equip-pathinfo-site-newsitename-geo",
    path: geoGW7u1KG1d5Meta?.path ?? ":newsitename/geo",
    meta: geoGW7u1KG1d5Meta || {},
    alias: geoGW7u1KG1d5Meta?.alias || [],
    redirect: geoGW7u1KG1d5Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[newsitename]/geo.vue").then(m => m.default || m)
  },
  {
    name: pvrPURQF0JuGMeta?.name ?? "toc-equip-pathinfo-site-newsitename-pv",
    path: pvrPURQF0JuGMeta?.path ?? ":newsitename/pv",
    meta: pvrPURQF0JuGMeta || {},
    alias: pvrPURQF0JuGMeta?.alias || [],
    redirect: pvrPURQF0JuGMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[newsitename]/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatKseStGWU0nMeta?.name ?? "toc-equip-pathinfo-site-newsitename-solarheat",
    path: solarheatKseStGWU0nMeta?.path ?? ":newsitename/solarheat",
    meta: solarheatKseStGWU0nMeta || {},
    alias: solarheatKseStGWU0nMeta?.alias || [],
    redirect: solarheatKseStGWU0nMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[newsitename]/solarheat.vue").then(m => m.default || m)
  },
  {
    name: _91sitename_93RK7vfHzd4mMeta?.name ?? "toc-equip-pathinfo-site-sitename",
    path: _91sitename_93RK7vfHzd4mMeta?.path ?? ":sitename",
    children: [
  {
    name: geoBbtbW6oG3GMeta?.name ?? "toc-equip-pathinfo-site-sitename-geo",
    path: geoBbtbW6oG3GMeta?.path ?? "geo",
    meta: geoBbtbW6oG3GMeta || {},
    alias: geoBbtbW6oG3GMeta?.alias || [],
    redirect: geoBbtbW6oG3GMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[sitename]/geo.vue").then(m => m.default || m)
  },
  {
    name: pv2ISMklEBdeMeta?.name ?? "toc-equip-pathinfo-site-sitename-pv",
    path: pv2ISMklEBdeMeta?.path ?? "pv",
    meta: pv2ISMklEBdeMeta || {},
    alias: pv2ISMklEBdeMeta?.alias || [],
    redirect: pv2ISMklEBdeMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[sitename]/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatU21zmbmKVMMeta?.name ?? "toc-equip-pathinfo-site-sitename-solarheat",
    path: solarheatU21zmbmKVMMeta?.path ?? "solarheat",
    meta: solarheatU21zmbmKVMMeta || {},
    alias: solarheatU21zmbmKVMMeta?.alias || [],
    redirect: solarheatU21zmbmKVMMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[sitename]/solarheat.vue").then(m => m.default || m)
  }
],
    meta: _91sitename_93RK7vfHzd4mMeta || {},
    alias: _91sitename_93RK7vfHzd4mMeta?.alias || [],
    redirect: _91sitename_93RK7vfHzd4mMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/[sitename].vue").then(m => m.default || m)
  },
  {
    name: indexrjB2LGVXoUMeta?.name ?? "toc-equip-pathinfo-site",
    path: indexrjB2LGVXoUMeta?.path ?? "",
    meta: indexrjB2LGVXoUMeta || {},
    alias: indexrjB2LGVXoUMeta?.alias || [],
    redirect: indexrjB2LGVXoUMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/index.vue").then(m => m.default || m)
  },
  {
    name: _91newsitename_93lRvNG7vETpMeta?.name ?? "toc-equip-pathinfo-site-test-newsitename",
    path: _91newsitename_93lRvNG7vETpMeta?.path ?? "test/:newsitename",
    meta: _91newsitename_93lRvNG7vETpMeta || {},
    alias: _91newsitename_93lRvNG7vETpMeta?.alias || [],
    redirect: _91newsitename_93lRvNG7vETpMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site/test/[newsitename].vue").then(m => m.default || m)
  }
],
    name: sitedx4SmCscPlMeta?.name ?? undefined,
    meta: sitedx4SmCscPlMeta || {},
    alias: sitedx4SmCscPlMeta?.alias || [],
    redirect: sitedx4SmCscPlMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip/[pathinfo]/site.vue").then(m => m.default || m)
  }
],
    meta: equipwNdz4pFSznMeta || {},
    alias: equipwNdz4pFSznMeta?.alias || [],
    redirect: equipwNdz4pFSznMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equip.vue").then(m => m.default || m)
  },
  {
    name: equipinfogasnucnT1FMeta?.name ?? "toc-equipinfo",
    path: equipinfogasnucnT1FMeta?.path ?? "equipinfo",
    meta: equipinfogasnucnT1FMeta || {},
    alias: equipinfogasnucnT1FMeta?.alias || [],
    redirect: equipinfogasnucnT1FMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/equipinfo.vue").then(m => m.default || m)
  },
  {
    name: errorTBtz4BCiMCMeta?.name ?? "toc-error",
    path: errorTBtz4BCiMCMeta?.path ?? "error",
    meta: errorTBtz4BCiMCMeta || {},
    alias: errorTBtz4BCiMCMeta?.alias || [],
    redirect: errorTBtz4BCiMCMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/error.vue").then(m => m.default || m)
  },
  {
    path: eventjn29opfZGQMeta?.path ?? "event",
    children: [
  {
    name: event_45listmvpsqQqsUKMeta?.name ?? "toc-event-event-list",
    path: event_45listmvpsqQqsUKMeta?.path ?? "event-list",
    children: [
  {
    name: _91eventId_93OxG43wCBM0Meta?.name ?? "toc-event-event-list-eventId",
    path: _91eventId_93OxG43wCBM0Meta?.path ?? ":eventId",
    meta: _91eventId_93OxG43wCBM0Meta || {},
    alias: _91eventId_93OxG43wCBM0Meta?.alias || [],
    redirect: _91eventId_93OxG43wCBM0Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/event/event-list/[eventId].vue").then(m => m.default || m)
  }
],
    meta: event_45listmvpsqQqsUKMeta || {},
    alias: event_45listmvpsqQqsUKMeta?.alias || [],
    redirect: event_45listmvpsqQqsUKMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/event/event-list.vue").then(m => m.default || m)
  },
  {
    name: indexqVWcS7gTvfMeta?.name ?? "toc-event",
    path: indexqVWcS7gTvfMeta?.path ?? "",
    meta: indexqVWcS7gTvfMeta || {},
    alias: indexqVWcS7gTvfMeta?.alias || [],
    redirect: indexqVWcS7gTvfMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/event/index.vue").then(m => m.default || m)
  }
],
    name: eventjn29opfZGQMeta?.name ?? undefined,
    meta: eventjn29opfZGQMeta || {},
    alias: eventjn29opfZGQMeta?.alias || [],
    redirect: eventjn29opfZGQMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/event.vue").then(m => m.default || m)
  },
  {
    name: installWGzdkThAwhMeta?.name ?? "toc-install",
    path: installWGzdkThAwhMeta?.path ?? "install",
    meta: installWGzdkThAwhMeta || {},
    alias: installWGzdkThAwhMeta?.alias || [],
    redirect: installWGzdkThAwhMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/install.vue").then(m => m.default || m)
  },
  {
    name: inventoryutabHcUwI2Meta?.name ?? "toc-inventory",
    path: inventoryutabHcUwI2Meta?.path ?? "inventory",
    children: [
  {
    name: addw7gxbHTWR1Meta?.name ?? "toc-inventory-equip-company-add",
    path: addw7gxbHTWR1Meta?.path ?? "equip-company/add",
    meta: addw7gxbHTWR1Meta || {},
    alias: addw7gxbHTWR1Meta?.alias || [],
    redirect: addw7gxbHTWR1Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/inventory/equip-company/add.vue").then(m => m.default || m)
  },
  {
    name: productumeMF0icl8Meta?.name ?? "toc-inventory-equip-company-product",
    path: productumeMF0icl8Meta?.path ?? "equip-company/product",
    meta: productumeMF0icl8Meta || {},
    alias: productumeMF0icl8Meta?.alias || [],
    redirect: productumeMF0icl8Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/inventory/equip-company/product.vue").then(m => m.default || m)
  }
],
    meta: inventoryutabHcUwI2Meta || {},
    alias: inventoryutabHcUwI2Meta?.alias || [],
    redirect: inventoryutabHcUwI2Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/inventory.vue").then(m => m.default || m)
  },
  {
    name: maintainencetl22VkvthDMeta?.name ?? "toc-maintainence",
    path: maintainencetl22VkvthDMeta?.path ?? "maintainence",
    meta: maintainencetl22VkvthDMeta || {},
    alias: maintainencetl22VkvthDMeta?.alias || [],
    redirect: maintainencetl22VkvthDMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/maintainence.vue").then(m => m.default || m)
  },
  {
    path: companyGgkrWIydcuMeta?.path ?? "management/company",
    children: [
  {
    name: add6heLm1X5UzMeta?.name ?? "toc-management-company-add",
    path: add6heLm1X5UzMeta?.path ?? "add",
    meta: add6heLm1X5UzMeta || {},
    alias: add6heLm1X5UzMeta?.alias || [],
    redirect: add6heLm1X5UzMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/company/add.vue").then(m => m.default || m)
  },
  {
    name: indexoL1IrQZAf5Meta?.name ?? "toc-management-company",
    path: indexoL1IrQZAf5Meta?.path ?? "",
    meta: indexoL1IrQZAf5Meta || {},
    alias: indexoL1IrQZAf5Meta?.alias || [],
    redirect: indexoL1IrQZAf5Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/company/index.vue").then(m => m.default || m)
  }
],
    name: companyGgkrWIydcuMeta?.name ?? undefined,
    meta: companyGgkrWIydcuMeta || {},
    alias: companyGgkrWIydcuMeta?.alias || [],
    redirect: companyGgkrWIydcuMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/company.vue").then(m => m.default || m)
  },
  {
    name: equip_45modelhLDTajjNsIMeta?.name ?? "toc-management-equip-model",
    path: equip_45modelhLDTajjNsIMeta?.path ?? "management/equip-model",
    meta: equip_45modelhLDTajjNsIMeta || {},
    alias: equip_45modelhLDTajjNsIMeta?.alias || [],
    redirect: equip_45modelhLDTajjNsIMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/equip-model.vue").then(m => m.default || m)
  },
  {
    path: equip73nLHFSnMWMeta?.path ?? "management/equip",
    children: [
  {
    name: addpuvt7qmzbQMeta?.name ?? "toc-management-equip-add",
    path: addpuvt7qmzbQMeta?.path ?? "add",
    children: [
  {
    name: find_45owner2sX58bEqp6Meta?.name ?? "toc-management-equip-add-find-owner",
    path: find_45owner2sX58bEqp6Meta?.path ?? "find-owner",
    meta: find_45owner2sX58bEqp6Meta || {},
    alias: find_45owner2sX58bEqp6Meta?.alias || [],
    redirect: find_45owner2sX58bEqp6Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/equip/add/find-owner.vue").then(m => m.default || m)
  },
  {
    name: geo_45specwdHmLWi6f1Meta?.name ?? "toc-management-equip-add-geo-spec",
    path: geo_45specwdHmLWi6f1Meta?.path ?? "geo-spec",
    meta: geo_45specwdHmLWi6f1Meta || {},
    alias: geo_45specwdHmLWi6f1Meta?.alias || [],
    redirect: geo_45specwdHmLWi6f1Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/equip/add/geo-spec.vue").then(m => m.default || m)
  },
  {
    name: pv_45spec5iPy8lEa4rMeta?.name ?? "toc-management-equip-add-pv-spec",
    path: pv_45spec5iPy8lEa4rMeta?.path ?? "pv-spec",
    meta: pv_45spec5iPy8lEa4rMeta || {},
    alias: pv_45spec5iPy8lEa4rMeta?.alias || [],
    redirect: pv_45spec5iPy8lEa4rMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/equip/add/pv-spec.vue").then(m => m.default || m)
  },
  {
    name: solarheat_45specmqwrDhtppjMeta?.name ?? "toc-management-equip-add-solarheat-spec",
    path: solarheat_45specmqwrDhtppjMeta?.path ?? "solarheat-spec",
    meta: solarheat_45specmqwrDhtppjMeta || {},
    alias: solarheat_45specmqwrDhtppjMeta?.alias || [],
    redirect: solarheat_45specmqwrDhtppjMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/equip/add/solarheat-spec.vue").then(m => m.default || m)
  }
],
    meta: addpuvt7qmzbQMeta || {},
    alias: addpuvt7qmzbQMeta?.alias || [],
    redirect: addpuvt7qmzbQMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/equip/add.vue").then(m => m.default || m)
  },
  {
    name: indexeAZYrA7qibMeta?.name ?? "toc-management-equip",
    path: indexeAZYrA7qibMeta?.path ?? "",
    meta: indexeAZYrA7qibMeta || {},
    alias: indexeAZYrA7qibMeta?.alias || [],
    redirect: indexeAZYrA7qibMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/equip/index.vue").then(m => m.default || m)
  }
],
    name: equip73nLHFSnMWMeta?.name ?? undefined,
    meta: equip73nLHFSnMWMeta || {},
    alias: equip73nLHFSnMWMeta?.alias || [],
    redirect: equip73nLHFSnMWMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/equip.vue").then(m => m.default || m)
  },
  {
    name: managementwSy3bVrvytMeta?.name ?? "toc-management-management",
    path: managementwSy3bVrvytMeta?.path ?? "management/management",
    meta: managementwSy3bVrvytMeta || {},
    alias: managementwSy3bVrvytMeta?.alias || [],
    redirect: managementwSy3bVrvytMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/management.vue").then(m => m.default || m)
  },
  {
    name: indexyslYUEPh8oMeta?.name ?? "toc-management-manager",
    path: indexyslYUEPh8oMeta?.path ?? "management/manager",
    meta: indexyslYUEPh8oMeta || {},
    alias: indexyslYUEPh8oMeta?.alias || [],
    redirect: indexyslYUEPh8oMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/manager/index.vue").then(m => m.default || m)
  },
  {
    name: notice4qbSkaslEIMeta?.name ?? "toc-management-notice",
    path: notice4qbSkaslEIMeta?.path ?? "management/notice",
    meta: notice4qbSkaslEIMeta || {},
    alias: notice4qbSkaslEIMeta?.alias || [],
    redirect: notice4qbSkaslEIMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/notice.vue").then(m => m.default || m)
  },
  {
    path: ownerUFVWmI18UXMeta?.path ?? "management/owner",
    children: [
  {
    name: addvj35zzO7fjMeta?.name ?? "toc-management-owner-add",
    path: addvj35zzO7fjMeta?.path ?? "add",
    meta: addvj35zzO7fjMeta || {},
    alias: addvj35zzO7fjMeta?.alias || [],
    redirect: addvj35zzO7fjMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/owner/add.vue").then(m => m.default || m)
  },
  {
    name: index1kYrLZxyy0Meta?.name ?? "toc-management-owner",
    path: index1kYrLZxyy0Meta?.path ?? "",
    meta: index1kYrLZxyy0Meta || {},
    alias: index1kYrLZxyy0Meta?.alias || [],
    redirect: index1kYrLZxyy0Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/owner/index.vue").then(m => m.default || m)
  }
],
    name: ownerUFVWmI18UXMeta?.name ?? undefined,
    meta: ownerUFVWmI18UXMeta || {},
    alias: ownerUFVWmI18UXMeta?.alias || [],
    redirect: ownerUFVWmI18UXMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/owner.vue").then(m => m.default || m)
  },
  {
    path: permit297lFD8i8iMeta?.path ?? "management/permit",
    children: [
  {
    name: indexUX2niuDhCAMeta?.name ?? "toc-management-permit",
    path: indexUX2niuDhCAMeta?.path ?? "",
    meta: indexUX2niuDhCAMeta || {},
    alias: indexUX2niuDhCAMeta?.alias || [],
    redirect: indexUX2niuDhCAMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/permit/index.vue").then(m => m.default || m)
  }
],
    name: permit297lFD8i8iMeta?.name ?? undefined,
    meta: permit297lFD8i8iMeta || {},
    alias: permit297lFD8i8iMeta?.alias || [],
    redirect: permit297lFD8i8iMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/management/permit.vue").then(m => m.default || m)
  },
  {
    name: memberqBk8UzFqhNMeta?.name ?? "toc-member",
    path: memberqBk8UzFqhNMeta?.path ?? "member",
    children: [
  {
    name: operatorjaDUPLZrqqMeta?.name ?? "toc-member-operator",
    path: operatorjaDUPLZrqqMeta?.path ?? "operator",
    meta: operatorjaDUPLZrqqMeta || {},
    alias: operatorjaDUPLZrqqMeta?.alias || [],
    redirect: operatorjaDUPLZrqqMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/member/operator.vue").then(m => m.default || m)
  },
  {
    name: _91id_93QTS5yiF2aUMeta?.name ?? "toc-member-owner-member-id",
    path: _91id_93QTS5yiF2aUMeta?.path ?? "owner-member/:id",
    children: [
  {
    name: geo3AKNx6TU0iMeta?.name ?? "toc-member-owner-member-id-geo",
    path: geo3AKNx6TU0iMeta?.path ?? "geo",
    meta: geo3AKNx6TU0iMeta || {},
    alias: geo3AKNx6TU0iMeta?.alias || [],
    redirect: geo3AKNx6TU0iMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/member/owner-member/[id]/geo.vue").then(m => m.default || m)
  },
  {
    name: pv1WX2VGwqMcMeta?.name ?? "toc-member-owner-member-id-pv",
    path: pv1WX2VGwqMcMeta?.path ?? "pv",
    meta: pv1WX2VGwqMcMeta || {},
    alias: pv1WX2VGwqMcMeta?.alias || [],
    redirect: pv1WX2VGwqMcMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/member/owner-member/[id]/pv.vue").then(m => m.default || m)
  },
  {
    name: solarheatFgDMzNV9FoMeta?.name ?? "toc-member-owner-member-id-solarheat",
    path: solarheatFgDMzNV9FoMeta?.path ?? "solarheat",
    meta: solarheatFgDMzNV9FoMeta || {},
    alias: solarheatFgDMzNV9FoMeta?.alias || [],
    redirect: solarheatFgDMzNV9FoMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/member/owner-member/[id]/solarheat.vue").then(m => m.default || m)
  }
],
    meta: _91id_93QTS5yiF2aUMeta || {},
    alias: _91id_93QTS5yiF2aUMeta?.alias || [],
    redirect: _91id_93QTS5yiF2aUMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/member/owner-member/[id].vue").then(m => m.default || m)
  },
  {
    name: owner_45registEsJquxEd7fMeta?.name ?? "toc-member-owner-member-owner-regist",
    path: owner_45registEsJquxEd7fMeta?.path ?? "owner-member/owner-regist",
    meta: owner_45registEsJquxEd7fMeta || {},
    alias: owner_45registEsJquxEd7fMeta?.alias || [],
    redirect: owner_45registEsJquxEd7fMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/member/owner-member/owner-regist.vue").then(m => m.default || m)
  },
  {
    name: owner_45searchMCeZdNpixmMeta?.name ?? "toc-member-owner-member-owner-search",
    path: owner_45searchMCeZdNpixmMeta?.path ?? "owner-member/owner-search",
    meta: owner_45searchMCeZdNpixmMeta || {},
    alias: owner_45searchMCeZdNpixmMeta?.alias || [],
    redirect: owner_45searchMCeZdNpixmMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/member/owner-member/owner-search.vue").then(m => m.default || m)
  },
  {
    name: ownerArWYccmI31Meta?.name ?? "toc-member-owner",
    path: ownerArWYccmI31Meta?.path ?? "owner",
    meta: ownerArWYccmI31Meta || {},
    alias: ownerArWYccmI31Meta?.alias || [],
    redirect: ownerArWYccmI31Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/member/owner.vue").then(m => m.default || m)
  },
  {
    name: systemjnyZscqxVSMeta?.name ?? "toc-member-system",
    path: systemjnyZscqxVSMeta?.path ?? "system",
    meta: systemjnyZscqxVSMeta || {},
    alias: systemjnyZscqxVSMeta?.alias || [],
    redirect: systemjnyZscqxVSMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/member/system.vue").then(m => m.default || m)
  }
],
    meta: memberqBk8UzFqhNMeta || {},
    alias: memberqBk8UzFqhNMeta?.alias || [],
    redirect: memberqBk8UzFqhNMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/member.vue").then(m => m.default || m)
  },
  {
    name: messageogaRHnEEYyMeta?.name ?? "toc-message",
    path: messageogaRHnEEYyMeta?.path ?? "message",
    children: [
  {
    name: alarm_45sendingF54OXBq0dIMeta?.name ?? "toc-message-alarm-sending",
    path: alarm_45sendingF54OXBq0dIMeta?.path ?? "alarm-sending",
    meta: alarm_45sendingF54OXBq0dIMeta || {},
    alias: alarm_45sendingF54OXBq0dIMeta?.alias || [],
    redirect: alarm_45sendingF54OXBq0dIMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/message/alarm-sending.vue").then(m => m.default || m)
  },
  {
    name: email_45sendingeVO5rt1PC9Meta?.name ?? "toc-message-email-sending",
    path: email_45sendingeVO5rt1PC9Meta?.path ?? "email-sending",
    meta: email_45sendingeVO5rt1PC9Meta || {},
    alias: email_45sendingeVO5rt1PC9Meta?.alias || [],
    redirect: email_45sendingeVO5rt1PC9Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/message/email-sending.vue").then(m => m.default || m)
  }
],
    meta: messageogaRHnEEYyMeta || {},
    alias: messageogaRHnEEYyMeta?.alias || [],
    redirect: messageogaRHnEEYyMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/message.vue").then(m => m.default || m)
  },
  {
    name: monitoringM2fibkyTM0Meta?.name ?? "toc-monitoring",
    path: monitoringM2fibkyTM0Meta?.path ?? "monitoring",
    children: [
  {
    name: comprehensive_45slideygFdxhGTB5Meta?.name ?? "toc-monitoring-comprehensive-slide",
    path: comprehensive_45slideygFdxhGTB5Meta?.path ?? "comprehensive-slide",
    meta: comprehensive_45slideygFdxhGTB5Meta || {},
    alias: comprehensive_45slideygFdxhGTB5Meta?.alias || [],
    redirect: comprehensive_45slideygFdxhGTB5Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/monitoring/comprehensive-slide.vue").then(m => m.default || m)
  },
  {
    name: dashboard7FgmyUq2NhMeta?.name ?? "toc-monitoring-dashboard",
    path: dashboard7FgmyUq2NhMeta?.path ?? "dashboard",
    meta: dashboard7FgmyUq2NhMeta || {},
    alias: dashboard7FgmyUq2NhMeta?.alias || [],
    redirect: dashboard7FgmyUq2NhMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/monitoring/dashboard.vue").then(m => m.default || m)
  },
  {
    name: equip_dashboardxgffREWwGKMeta?.name ?? "toc-monitoring-equip_dashboard",
    path: equip_dashboardxgffREWwGKMeta?.path ?? "equip_dashboard",
    meta: equip_dashboardxgffREWwGKMeta || {},
    alias: equip_dashboardxgffREWwGKMeta?.alias || [],
    redirect: equip_dashboardxgffREWwGKMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/monitoring/equip_dashboard.vue").then(m => m.default || m)
  },
  {
    name: newDashboardPd9FybYg0qMeta?.name ?? "toc-monitoring-newDashboard",
    path: newDashboardPd9FybYg0qMeta?.path ?? "newDashboard",
    meta: newDashboardPd9FybYg0qMeta || {},
    alias: newDashboardPd9FybYg0qMeta?.alias || [],
    redirect: newDashboardPd9FybYg0qMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/monitoring/newDashboard.vue").then(m => m.default || m)
  },
  {
    name: prod_45slideXiqFkuAn8yMeta?.name ?? "toc-monitoring-prod-slide",
    path: prod_45slideXiqFkuAn8yMeta?.path ?? "prod-slide",
    meta: prod_45slideXiqFkuAn8yMeta || {},
    alias: prod_45slideXiqFkuAn8yMeta?.alias || [],
    redirect: prod_45slideXiqFkuAn8yMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/monitoring/prod-slide.vue").then(m => m.default || m)
  },
  {
    name: temp_newDashboardzpD3Hq74EGMeta?.name ?? "toc-monitoring-temp_newDashboard",
    path: temp_newDashboardzpD3Hq74EGMeta?.path ?? "temp_newDashboard",
    meta: temp_newDashboardzpD3Hq74EGMeta || {},
    alias: temp_newDashboardzpD3Hq74EGMeta?.alias || [],
    redirect: temp_newDashboardzpD3Hq74EGMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/monitoring/temp_newDashboard.vue").then(m => m.default || m)
  }
],
    meta: monitoringM2fibkyTM0Meta || {},
    alias: monitoringM2fibkyTM0Meta?.alias || [],
    redirect: monitoringM2fibkyTM0Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/monitoring.vue").then(m => m.default || m)
  },
  {
    name: not_45foundOgIxJQzcqMMeta?.name ?? "toc-not-found",
    path: not_45foundOgIxJQzcqMMeta?.path ?? "not-found",
    meta: not_45foundOgIxJQzcqMMeta || {},
    alias: not_45foundOgIxJQzcqMMeta?.alias || [],
    redirect: not_45foundOgIxJQzcqMMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/not-found.vue").then(m => m.default || m)
  },
  {
    name: operate0IofEfiy1dMeta?.name ?? "toc-operate",
    path: operate0IofEfiy1dMeta?.path ?? "operate",
    meta: operate0IofEfiy1dMeta || {},
    alias: operate0IofEfiy1dMeta?.alias || [],
    redirect: operate0IofEfiy1dMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/operate.vue").then(m => m.default || m)
  },
  {
    name: reportRwYariHM2iMeta?.name ?? "toc-report",
    path: reportRwYariHM2iMeta?.path ?? "report",
    children: [
  {
    name: analyze_45efficientz2woU1E1UYMeta?.name ?? "toc-report-analyze-efficient",
    path: analyze_45efficientz2woU1E1UYMeta?.path ?? "analyze-efficient",
    children: [
  {
    name: ownersNd1Q78yGldMeta?.name ?? "toc-report-analyze-efficient-owners",
    path: ownersNd1Q78yGldMeta?.path ?? "owners",
    meta: ownersNd1Q78yGldMeta || {},
    alias: ownersNd1Q78yGldMeta?.alias || [],
    redirect: ownersNd1Q78yGldMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report/analyze-efficient/owners.vue").then(m => m.default || m)
  },
  {
    name: prjkindsIkIVANuebVMeta?.name ?? "toc-report-analyze-efficient-prjkinds",
    path: prjkindsIkIVANuebVMeta?.path ?? "prjkinds",
    meta: prjkindsIkIVANuebVMeta || {},
    alias: prjkindsIkIVANuebVMeta?.alias || [],
    redirect: prjkindsIkIVANuebVMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report/analyze-efficient/prjkinds.vue").then(m => m.default || m)
  },
  {
    name: regionsV7NzJsAumeMeta?.name ?? "toc-report-analyze-efficient-regions",
    path: regionsV7NzJsAumeMeta?.path ?? "regions",
    meta: regionsV7NzJsAumeMeta || {},
    alias: regionsV7NzJsAumeMeta?.alias || [],
    redirect: regionsV7NzJsAumeMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report/analyze-efficient/regions.vue").then(m => m.default || m)
  }
],
    meta: analyze_45efficientz2woU1E1UYMeta || {},
    alias: analyze_45efficientz2woU1E1UYMeta?.alias || [],
    redirect: analyze_45efficientz2woU1E1UYMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report/analyze-efficient.vue").then(m => m.default || m)
  },
  {
    name: analyze_45productznon1gFymSMeta?.name ?? "toc-report-analyze-product",
    path: analyze_45productznon1gFymSMeta?.path ?? "analyze-product",
    children: [
  {
    name: ownersujm05i3nS8Meta?.name ?? "toc-report-analyze-product-owners",
    path: ownersujm05i3nS8Meta?.path ?? "owners",
    meta: ownersujm05i3nS8Meta || {},
    alias: ownersujm05i3nS8Meta?.alias || [],
    redirect: ownersujm05i3nS8Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report/analyze-product/owners.vue").then(m => m.default || m)
  },
  {
    name: prjkindskajS0wlKDYMeta?.name ?? "toc-report-analyze-product-prjkinds",
    path: prjkindskajS0wlKDYMeta?.path ?? "prjkinds",
    meta: prjkindskajS0wlKDYMeta || {},
    alias: prjkindskajS0wlKDYMeta?.alias || [],
    redirect: prjkindskajS0wlKDYMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report/analyze-product/prjkinds.vue").then(m => m.default || m)
  },
  {
    name: regions1T9DX7u30mMeta?.name ?? "toc-report-analyze-product-regions",
    path: regions1T9DX7u30mMeta?.path ?? "regions",
    meta: regions1T9DX7u30mMeta || {},
    alias: regions1T9DX7u30mMeta?.alias || [],
    redirect: regions1T9DX7u30mMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report/analyze-product/regions.vue").then(m => m.default || m)
  }
],
    meta: analyze_45productznon1gFymSMeta || {},
    alias: analyze_45productznon1gFymSMeta?.alias || [],
    redirect: analyze_45productznon1gFymSMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report/analyze-product.vue").then(m => m.default || m)
  },
  {
    name: env_45factorZd55QjJn6lMeta?.name ?? "toc-report-env-factor",
    path: env_45factorZd55QjJn6lMeta?.path ?? "env-factor",
    meta: env_45factorZd55QjJn6lMeta || {},
    alias: env_45factorZd55QjJn6lMeta?.alias || [],
    redirect: env_45factorZd55QjJn6lMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report/env-factor.vue").then(m => m.default || m)
  },
  {
    name: eventlTJx5OEyxYMeta?.name ?? "toc-report-event",
    path: eventlTJx5OEyxYMeta?.path ?? "event",
    meta: eventlTJx5OEyxYMeta || {},
    alias: eventlTJx5OEyxYMeta?.alias || [],
    redirect: eventlTJx5OEyxYMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report/event.vue").then(m => m.default || m)
  },
  {
    name: predictsbjmCOnP3OMeta?.name ?? "toc-report-predict",
    path: predictsbjmCOnP3OMeta?.path ?? "predict",
    meta: predictsbjmCOnP3OMeta || {},
    alias: predictsbjmCOnP3OMeta?.alias || [],
    redirect: predictsbjmCOnP3OMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report/predict.vue").then(m => m.default || m)
  },
  {
    name: productlp2MM1Lf28Meta?.name ?? "toc-report-product",
    path: productlp2MM1Lf28Meta?.path ?? "product",
    meta: productlp2MM1Lf28Meta || {},
    alias: productlp2MM1Lf28Meta?.alias || [],
    redirect: productlp2MM1Lf28Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report/product.vue").then(m => m.default || m)
  }
],
    meta: reportRwYariHM2iMeta || {},
    alias: reportRwYariHM2iMeta?.alias || [],
    redirect: reportRwYariHM2iMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/report.vue").then(m => m.default || m)
  },
  {
    name: service_45infoKev3UpoGfkMeta?.name ?? "toc-service-info",
    path: service_45infoKev3UpoGfkMeta?.path ?? "service-info",
    children: [
  {
    name: app_45version4BHOdH1s18Meta?.name ?? "toc-service-info-app-version",
    path: app_45version4BHOdH1s18Meta?.path ?? "app-version",
    meta: app_45version4BHOdH1s18Meta || {},
    alias: app_45version4BHOdH1s18Meta?.alias || [],
    redirect: app_45version4BHOdH1s18Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/service-info/app-version.vue").then(m => m.default || m)
  }
],
    meta: service_45infoKev3UpoGfkMeta || {},
    alias: service_45infoKev3UpoGfkMeta?.alias || [],
    redirect: service_45infoKev3UpoGfkMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/service-info.vue").then(m => m.default || m)
  },
  {
    name: testMKlcs1AMQ3Meta?.name ?? "toc-test",
    path: testMKlcs1AMQ3Meta?.path ?? "test",
    meta: testMKlcs1AMQ3Meta || {},
    alias: testMKlcs1AMQ3Meta?.alias || [],
    redirect: testMKlcs1AMQ3Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/test.vue").then(m => m.default || m)
  },
  {
    name: user_45servicepyarq6WQ7OMeta?.name ?? "toc-user-service",
    path: user_45servicepyarq6WQ7OMeta?.path ?? "user-service",
    children: [
  {
    name: manager_45info2khEkiqIuMMeta?.name ?? "toc-user-service-manager-info",
    path: manager_45info2khEkiqIuMMeta?.path ?? "manager-info",
    meta: manager_45info2khEkiqIuMMeta || {},
    alias: manager_45info2khEkiqIuMMeta?.alias || [],
    redirect: manager_45info2khEkiqIuMMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user-service/manager-info.vue").then(m => m.default || m)
  },
  {
    name: confirm_45modalLN60Wa0hONMeta?.name ?? "toc-user-service-modal-confirm-modal",
    path: confirm_45modalLN60Wa0hONMeta?.path ?? "modal/confirm-modal",
    meta: confirm_45modalLN60Wa0hONMeta || {},
    alias: confirm_45modalLN60Wa0hONMeta?.alias || [],
    redirect: confirm_45modalLN60Wa0hONMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user-service/modal/confirm-modal.vue").then(m => m.default || m)
  },
  {
    name: manager_45add_45modalx2Wd7mvxN4Meta?.name ?? "toc-user-service-modal-manager-add-modal",
    path: manager_45add_45modalx2Wd7mvxN4Meta?.path ?? "modal/manager-add-modal",
    meta: manager_45add_45modalx2Wd7mvxN4Meta || {},
    alias: manager_45add_45modalx2Wd7mvxN4Meta?.alias || [],
    redirect: manager_45add_45modalx2Wd7mvxN4Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user-service/modal/manager-add-modal.vue").then(m => m.default || m)
  },
  {
    name: manager_45modify_45modaloeUWukCV6YMeta?.name ?? "toc-user-service-modal-manager-modify-modal",
    path: manager_45modify_45modaloeUWukCV6YMeta?.path ?? "modal/manager-modify-modal",
    meta: manager_45modify_45modaloeUWukCV6YMeta || {},
    alias: manager_45modify_45modaloeUWukCV6YMeta?.alias || [],
    redirect: manager_45modify_45modaloeUWukCV6YMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user-service/modal/manager-modify-modal.vue").then(m => m.default || m)
  },
  {
    name: set_45manager_45all_45modalexXpsNy9nqMeta?.name ?? "toc-user-service-modal-set-manager-all-modal",
    path: set_45manager_45all_45modalexXpsNy9nqMeta?.path ?? "modal/set-manager-all-modal",
    meta: set_45manager_45all_45modalexXpsNy9nqMeta || {},
    alias: set_45manager_45all_45modalexXpsNy9nqMeta?.alias || [],
    redirect: set_45manager_45all_45modalexXpsNy9nqMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user-service/modal/set-manager-all-modal.vue").then(m => m.default || m)
  },
  {
    name: set_45manager_45select_45modalgsdvq34nxIMeta?.name ?? "toc-user-service-modal-set-manager-select-modal",
    path: set_45manager_45select_45modalgsdvq34nxIMeta?.path ?? "modal/set-manager-select-modal",
    meta: set_45manager_45select_45modalgsdvq34nxIMeta || {},
    alias: set_45manager_45select_45modalgsdvq34nxIMeta?.alias || [],
    redirect: set_45manager_45select_45modalgsdvq34nxIMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user-service/modal/set-manager-select-modal.vue").then(m => m.default || m)
  },
  {
    name: set_45manager1jV4Us1xD1Meta?.name ?? "toc-user-service-set-manager",
    path: set_45manager1jV4Us1xD1Meta?.path ?? "set-manager",
    meta: set_45manager1jV4Us1xD1Meta || {},
    alias: set_45manager1jV4Us1xD1Meta?.alias || [],
    redirect: set_45manager1jV4Us1xD1Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user-service/set-manager.vue").then(m => m.default || m)
  },
  {
    name: user_45infouxi5dvWzXHMeta?.name ?? "toc-user-service-user-info",
    path: user_45infouxi5dvWzXHMeta?.path ?? "user-info",
    meta: user_45infouxi5dvWzXHMeta || {},
    alias: user_45infouxi5dvWzXHMeta?.alias || [],
    redirect: user_45infouxi5dvWzXHMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user-service/user-info.vue").then(m => m.default || m)
  },
  {
    name: user_45settingdID9Lrt0HqMeta?.name ?? "toc-user-service-user-setting",
    path: user_45settingdID9Lrt0HqMeta?.path ?? "user-setting",
    meta: user_45settingdID9Lrt0HqMeta || {},
    alias: user_45settingdID9Lrt0HqMeta?.alias || [],
    redirect: user_45settingdID9Lrt0HqMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user-service/user-setting.vue").then(m => m.default || m)
  }
],
    meta: user_45servicepyarq6WQ7OMeta || {},
    alias: user_45servicepyarq6WQ7OMeta?.alias || [],
    redirect: user_45servicepyarq6WQ7OMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user-service.vue").then(m => m.default || m)
  },
  {
    name: find_45idpmYD4u5lqaMeta?.name ?? "toc-user-find-id",
    path: find_45idpmYD4u5lqaMeta?.path ?? "user/find-id",
    meta: find_45idpmYD4u5lqaMeta || {},
    alias: find_45idpmYD4u5lqaMeta?.alias || [],
    redirect: find_45idpmYD4u5lqaMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user/find-id.vue").then(m => m.default || m)
  },
  {
    name: indexVzv8uNLVpxMeta?.name ?? "toc-user",
    path: indexVzv8uNLVpxMeta?.path ?? "user",
    meta: indexVzv8uNLVpxMeta || {},
    alias: indexVzv8uNLVpxMeta?.alias || [],
    redirect: indexVzv8uNLVpxMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user/index.vue").then(m => m.default || m)
  },
  {
    name: loginYEsMGRJ2V8Meta?.name ?? "toc-user-login",
    path: loginYEsMGRJ2V8Meta?.path ?? "user/login",
    meta: loginYEsMGRJ2V8Meta || {},
    alias: loginYEsMGRJ2V8Meta?.alias || [],
    redirect: loginYEsMGRJ2V8Meta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc/user/login.vue").then(m => m.default || m)
  }
],
    meta: tocwun8sciQGaMeta || {},
    alias: tocwun8sciQGaMeta?.alias || [],
    redirect: tocwun8sciQGaMeta?.redirect || undefined,
    component: () => import("/home/kbet/rems/kie-rems-mweb/src/client/pages/toc.vue").then(m => m.default || m)
  }
]