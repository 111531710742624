import validate from "/home/kbet/rems/kie-rems-mweb/node_modules/.pnpm/nuxt@3.3.3_@types+node@18.19.3_rollup@4.9.0_sass@1.69.5/node_modules/nuxt/dist/pages/runtime/validate.js";
export const globalMiddleware = [
  validate
]
export const namedMiddleware = {
  "api-proxy": () => import("/home/kbet/rems/kie-rems-mweb/src/client/middleware/api-proxy.ts"),
  "auto-login": () => import("/home/kbet/rems/kie-rems-mweb/src/client/middleware/autoLogin.ts"),
  "block-route": () => import("/home/kbet/rems/kie-rems-mweb/src/client/middleware/block-route.ts"),
  "event-page-layout": () => import("/home/kbet/rems/kie-rems-mweb/src/client/middleware/eventPageLayout.ts"),
  "page-slide": () => import("/home/kbet/rems/kie-rems-mweb/src/client/middleware/page-slide.ts"),
  "set-intro": () => import("/home/kbet/rems/kie-rems-mweb/src/client/middleware/setIntro.ts"),
  "set-page-layout": () => import("/home/kbet/rems/kie-rems-mweb/src/client/middleware/setPageLayout.ts"),
  "toc-route-middleware": () => import("/home/kbet/rems/kie-rems-mweb/src/client/middleware/toc-route-middleware.ts")
}